import axios from 'axios';
import { UserRole } from '@utils/main.utils';
import {
  LoginUserModel,
  ForgetPasswordModel,
  SetMewPasswordModel,
  RegisterNewUserModel,
} from '../../types/auth';

export const Login = async (
  credentials: LoginUserModel,
): Promise<{ token: string; role: UserRole; qrCode?: string }> => {
  try {
    const response = await axios.post('/api/v1/users/login', credentials);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const register = async (credentials: RegisterNewUserModel): Promise<string> => {
  try {
    const response = await axios.post('/api/v1/users/register', credentials);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const approveEmail = async (payload: {
  token: string;
}): Promise<{ token: string; role: UserRole; qrCode?: string }> => {
  try {
    let response = await axios.post(
      '/api/v2/users/confirm-email',
      {},
      {
        headers: {
          confirmationToken: payload.token,
        },
      },
    );
    return response.data.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const approveForgetEmail = async (payload: { token: string }): Promise<string | null> => {
  try {
    let response = await axios.get('/api/v1/users/forget-password/validation-token', {
      headers: {
        confirmationToken: payload.token,
      },
    });
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const resendVerificationLink = async (payload: {
  token: string;
  redirectUrl?: string;
}): Promise<string | null> => {
  try {
    let response = await axios.post(
      '/api/v1/users/register/resend-email',
      {},
      {
        headers: {
          confirmationToken: payload.token,
          ...(payload.redirectUrl && { redirectUrl: payload.redirectUrl }),
        },
      },
    );
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const forgetPassword = async (credentials: ForgetPasswordModel): Promise<string> => {
  try {
    const response = await axios.post('/api/v1/users/forget-password', credentials);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setNewPassword = async (payload: SetMewPasswordModel): Promise<string> => {
  try {
    const response = await axios.post(
      '/api/v1/users/forget-password/set-password',
      {
        newPassword: payload.password,
      },
      {
        headers: {
          confirmationToken: payload.token,
        },
      },
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const twoFaAuthenticate = async (
  code: string,
): Promise<{ token: string; role: UserRole }> => {
  try {
    const response = await axios.post('/api/v1/users/2fa-authenticate', { code });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmGoogle = async (
  code: string,
  referralCode?: string,
): Promise<{ data: { token: string; role: UserRole }; meta?: { register?: boolean } }> => {
  try {
    const response = await axios.get('/api/v1/users/google/confirm', {
      params: { code, referralCode },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmFacebook = async (
  code: string,
  referralCode?: string,
): Promise<{ data: { token: string; role: UserRole }; meta?: { register: boolean } }> => {
  try {
    const response = await axios.get('/api/v1/users/facebook/confirm', {
      params: { code, referralCode },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logout = (): void => {
  try {
    localStorage.removeItem('state');
    // eslint-disable-next-line no-empty
  } catch (error: any) {}
};

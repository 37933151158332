import { useSelector } from 'react-redux';
import { UserRole } from '../utils/main.utils';
import { selectAuthRole, selectAuthToken } from '@stores/selectors/auth.selector';

export const useAuth = () => {
  const userRole = useSelector(selectAuthRole);
  const userToken = useSelector(selectAuthToken);

  return {
    isAuthenticated:
      !!userToken && userRole !== null && [UserRole.USER, UserRole.ADMIN].includes(userRole),
  };
};

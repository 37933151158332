import axios from 'axios';

export const labels = async () => {
  try {
    const response = await axios.get('/api/v1/labels');
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createLabel = async (data: any) => {
  try {
    const response = await axios.post('/api/v1/labels', data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteLabel = async (id: string) => {
  try {
    const response = await axios.delete(`/api/v1/labels/${id}`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLabel = async (id: string, data: any) => {
  try {
    const response = await axios.patch(`/api/v1/labels/${id}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

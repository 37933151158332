import { airdropApi } from '../../api/airdrop';
import { call, put, takeLatest } from 'redux-saga/effects';
import { subscribersActions } from '@stores/slices/subscribers.slice';
import { AddSubscriberModal } from '../../types/subscribers';
import { PayloadAction } from '@reduxjs/toolkit';

export function* addSubscriber({ payload }: PayloadAction<AddSubscriberModal>): Generator<any> {
  try {
    yield call(airdropApi.subscribers.addSubscriber, payload);
    yield put(subscribersActions.addSubscriberSuccess());
  } catch (error: any) {
    yield put(subscribersActions.addSubscriberFail({ error: error?.response?.data }));
  }
}

export function* subscribersSaga(): Generator<any> {
  yield takeLatest(<any>subscribersActions.addSubscriber, addSubscriber);
}

import { UserRole } from '@utils/main.utils';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAuthRole, selectAuthToken } from '../stores/selectors/auth.selector';

type PrivateRouteProps = {
  outlet: JSX.Element;
  roles?: UserRole[];
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const userToken = useSelector(selectAuthToken);
  const userRole = useSelector(selectAuthRole);

  if (!props.roles && userToken) return props.outlet;
  else if (userRole != null && props.roles?.includes(userRole)) return props.outlet;

  return <Navigate to="/" replace={true} />;
};

export default PrivateRoute;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';
import './GoogleTranslate.scss';
import { ReactComponent as GlobeIcon } from '../../../assets/images/globe.svg';

type GoogleTranslateProps = {
  className?: string;
};

const GoogleTranslate = (props: GoogleTranslateProps) => {
  const googleTranslateElementInit = () => {
    const children = document.getElementById('google_translate_element')?.children;

    if (children?.length == 1)
      new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: false,
        },
        'google_translate_element',
      );
  };

  useEffect(() => {
    const addScript = document.createElement('script');
    addScript.async = true;
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    document.body.appendChild(addScript);
    (window as any).googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`google_translate_wrapper ${props.className}`}>
      <div id="google_translate_element">
        <div className="google_translate_select">
          <GlobeIcon width={24} height={24} fill={'#434446'} />
        </div>
      </div>
    </label>
  );
};

export default GoogleTranslate;

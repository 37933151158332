import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import classes from './Header.module.scss';
import logoLight from '../../../../../assets/images/logo-light.svg';
import emptyAvatarIcon from '@assets/images/emptyAvatar.svg';
import AppButton from '@components/atoms/AppButton/AppButton';
import MainMenu from '@components/Layouts/AppPage/components/MainMenu/MainMenu';
import EnableCookies from '@components/molecules/EnableCookies/EnableCookies';
import GoogleTranslate from '../../../../molecules/GoogleTranslate/GoogleTranslate';
// import BannerExosamaMoonsama from '@components/molecules/BannerExosamaMoonsama/BannerExosamaMoonsama';
// import { ReactComponent as CoinIcon } from '../../../../../assets/images/coin.svg';
// import { ReactComponent as ReferralsIcon } from '../../../../../assets/images/referrals.svg';
import { useAuth } from '../../../../../hooks/useAuth';
import { currentRoute } from '../../../../../utils/main.utils';
import { createBreakpoint } from 'react-use';
import { ReactComponent as MailIcon } from '../../../../../assets/images/mail2.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/info2.svg';
import { ReactComponent as QuizIcon } from '../../../../../assets/images/help.svg';
import { ReactComponent as MenuIcon } from '../../../../../assets/images/menu.svg';
import { ReactComponent as TwitterIcon } from '../../../../../assets/images/twitter.svg';
import { ReactComponent as TelegramIcon } from '../../../../../assets/images/telegram.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../../assets/images/chevron-down.svg';
import {
  selectUser,
  selectCookieIsEnabled,
  selectCookieBannerIsShown,
} from '@stores/selectors/user.selector';

interface Props {
  keepSpace?: boolean;
}

const useBreakpoint = createBreakpoint({
  desktop: 1199,
  laptop: 1024,
  tablet: 992,
  mobileL: 576,
  mobileM: 320,
  mobileS: 0,
});

function Header({ keepSpace }: Props) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const cookieIsEnabled = useSelector(selectCookieIsEnabled);
  const cookieBannerIsShown = useSelector(selectCookieBannerIsShown);
  const { isAuthenticated } = useAuth();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [openedSubmenu, setOpenedSubmenu] = useState<number | null>(null);
  // @ts-ignore
  const breakpoint: 'desktop' | 'laptop' | 'tablet' | 'mobile' = useBreakpoint();

  const menuElements = useMemo(
    () => [
      {
        id: 1,
        label: 'Home',
        to: '/',
      },
      // {
      //   id: 2,
      //   label: 'Affiliate',
      //   to: '/affiliate-program',
      // },
      {
        id: 3,
        label: 'Tasker',
        to: '/tasker',
      },
      {
        id: 4,
        label: 'Contact',
        to: '/contact-us',
      },
      {
        id: 5,
        label: 'More',
        children: [
          // {
          //   id: 6,
          //   label: 'Become Partner',
          //   to: '/rewards',
          //   icon: <ReferralsIcon width={24} height={24} fill={'#0B0B0C'} />,
          // },
          {
            id: 7,
            label: 'FAQ',
            to: '/faq',
            icon: <QuizIcon width={24} height={24} fill={'#0B0B0C'} />,
          },
          {
            id: 8,
            label: 'About Us',
            to: '/about-us',
            icon: <InfoIcon width={24} height={24} fill={'#0B0B0C'} />,
          },
          {
            id: 9,
            label: 'Newsletter',
            to: '/newsletter',
            icon: <MailIcon width={24} height={24} fill={'#0B0B0C'} />,
          },
        ],
      },
    ],
    [],
  );

  const openSubmenu = (parentId: number) => {
    if (openedSubmenu !== parentId) {
      setOpenedSubmenu(parentId);
    }
  };

  const toggleMenu = useCallback((value: boolean) => {
    setMenuIsOpen(value);
  }, []);

  const renderSubmenu = useCallback((parentId: number, items: any) => {
    return (
      <div className={classNames(['navigationSubmenu', classes.navigationSubmenu])}>
        {items.map((element: any) => {
          return (
            <NavLink
              end
              to={element.to}
              key={element.id}
              className={({ isActive }) =>
                classNames([
                  classes.navigationSubmenuItem,
                  { [classes.navigationSubmenuItemActive]: isActive },
                ])
              }
            >
              <span className={classes.navigationSubmenuItemIcon}>{element.icon}</span>
              <span className={classes.navigationSubmenuItemLabel}>{element.label}</span>
            </NavLink>
          );
        })}
      </div>
    );
  }, []);

  const checkAndCloseSubmenus = useCallback(
    (event: MouseEvent) => {
      if (openedSubmenu && event.target) {
        const outsideClick = (event.target as HTMLElement).closest('.navigationSubmenu');
        if (!outsideClick && !(event.target as HTMLElement).closest(`[data-dd-trigger="true"]`)) {
          setOpenedSubmenu(null);
        }
      }
    },
    [openedSubmenu],
  );

  useEffect(() => {
    window.addEventListener('click', checkAndCloseSubmenus);
    return () => {
      window.removeEventListener('click', checkAndCloseSubmenus);
    };
  }, [checkAndCloseSubmenus]);

  return (
    <div
      className={classNames([
        breakpoint,
        classes.headerWrapper,
        // { [classes.partnersBanner]: !currentRoute.is(currentRoute.routNames.TULIP) },
        { [classes.cookieBanner]: !cookieIsEnabled },
        { [classes.cookieBannerShown]: cookieBannerIsShown },
      ])}
    >
      <div className={classes.headerContainer} data-testid="header">
        <EnableCookies />
        {/*{!currentRoute.is(currentRoute.routNames.TULIP) && <BannerExosamaMoonsama />}*/}
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <span
              aria-hidden
              data-testid={'burger-button'}
              className={classes.headerMenuIcon}
              onClick={() => toggleMenu(true)}
            >
              <MenuIcon width={24} height={24} fill={'#434446'} />
            </span>
            <figure className={classes.headerLogo} aria-hidden onClick={() => navigate('/')}>
              <img src={logoLight} alt="logo" width={'100%'} height={'100%'} />
            </figure>
            <div className={classes.navigation}>
              {menuElements.map((element) => {
                return element.children ? (
                  <span
                    aria-hidden
                    key={element.id}
                    data-dd-trigger="true"
                    onClick={() => openSubmenu(element.id)}
                    className={classNames([
                      classes.navigationItem,
                      { [classes.navigationItemOpenedSubmenu]: openedSubmenu === element.id },
                      {
                        [classes.navigationItemActive]:
                          currentRoute.is(currentRoute.routNames.FAQ) ||
                          currentRoute.is(currentRoute.routNames.ABOUT_US) ||
                          currentRoute.is(currentRoute.routNames.NEWSLETTER),
                      },
                    ])}
                  >
                    <span className={classes.navigationItemLabel}>{element.label}</span>
                    <span className={classes.navigationItemArrow}>
                      <ChevronDownIcon width={16} height={16} fill={'#434446'} />
                    </span>
                    {renderSubmenu(element.id, element.children)}
                  </span>
                ) : (
                  <NavLink
                    end
                    to={element.to}
                    key={element.id}
                    className={({ isActive }) =>
                      classNames([
                        classes.navigationItem,
                        { [classes.navigationItemActive]: isActive },
                      ])
                    }
                  >
                    <span className={classes.navigationItemLabel}>{element.label}</span>
                  </NavLink>
                );
              })}
            </div>
          </div>
          <div className={classes.headerRight}>
            <div className={classes.headerRightMain}>
              <NavLink to={'/telegram-channel'} className={classes.headerLink}>
                <TelegramIcon width={24} height={24} fill={'#2AABEE'} />
              </NavLink>
              <NavLink to={'/twitter-page'} className={classes.headerLink}>
                <span className={classes.headerLinkIcon}>
                  <TwitterIcon width={24} height={24} fill={'#1D9BF0'} />
                </span>
              </NavLink>
              {/*<NavLink*/}
              {/*  to={'/airdrop-bounty'}*/}
              {/*  className={classNames(classes.headerLink, classes.headerLinkBounty)}*/}
              {/*>*/}
              {/*  <span className={classes.headerLinkIcon}>*/}
              {/*    <CoinIcon width={24} height={24} fill={'#0069DB'} />*/}
              {/*  </span>*/}
              {/*  <span className={classes.headerLinkLabel}>Earn Bounty</span>*/}
              {/*</NavLink>*/}
              {['mobileL', 'tablet', 'laptop', 'desktop'].includes(breakpoint) && (
                <div className={classes.translate}>
                  <GoogleTranslate />
                </div>
              )}
              {!isAuthenticated && (
                <>
                  <AppButton
                    text={'Log In'}
                    size={
                      ['mobileS', 'mobileM', 'mobileL', 'tableSmall'].includes(breakpoint)
                        ? 'small'
                        : 'default'
                    }
                    variant={'secondary'}
                    onClick={() => navigate('/login')}
                    className={classes.loginButton}
                  />

                  <AppButton
                    text={'Sign Up'}
                    size={
                      ['mobileS', 'mobileM', 'mobileL', 'tableSmall'].includes(breakpoint)
                        ? 'small'
                        : 'default'
                    }
                    variant={'primary'}
                    onClick={() => navigate('/sign-up')}
                  />
                </>
              )}
            </div>
            {isAuthenticated && (
              <div className={classes.profileLinkWrapper}>
                <NavLink
                  end
                  to={'/profile'}
                  className={({ isActive }) =>
                    classNames([
                      classes.navigationItem,
                      { [classes.navigationItemActive]: isActive },
                    ])
                  }
                >
                  <img
                    src={
                      user?.picture
                        ? `${process.env.REACT_APP_API_HOST}/api/v1/file-upload/user/profile-picture/${user.picture}`
                        : emptyAvatarIcon
                    }
                    alt=""
                    className={classes.profileLinkAvatar}
                  />

                  <span className={classes.navigationItemLabel}>Profile</span>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
      {keepSpace && <div className={classes.headerPadding} />}
      <MainMenu isOpen={menuIsOpen} onClose={() => toggleMenu(false)} />
    </div>
  );
}

export default Header;

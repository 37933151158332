import axios from 'axios';

export const getReferral = async (referralCode: string): Promise<string> => {
  try {
    const response = await axios.get(`/api/v1/users/referrals/${referralCode}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

import axios from 'axios';
import { IAirdropTaskerDetails } from '../../types/tasker';

export const getAirdropTaskerDetails = async (params: {
  airdropId: string;
}): Promise<IAirdropTaskerDetails | null> => {
  try {
    const response = await axios.get(`/api/v1/tasker/tasks/${params.airdropId}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeReplyTask = async (params: {
  taskId: string;
  reply: string;
}): Promise<{ success: true }> => {
  try {
    const response = await axios.post(`/api/v1/tasker/complete/reply/${params.taskId}`, {
      reply: params.reply,
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeFollowTask = async (params: {
  taskId: string;
}): Promise<{ success: true }> => {
  try {
    const response = await axios.post(`/api/v1/tasker/complete/follow/${params.taskId}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeRetweetTask = async (params: {
  taskId: string;
}): Promise<{ success: true }> => {
  try {
    const response = await axios.post(`/api/v1/tasker/complete/retweet/${params.taskId}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeRetweetWithCommentTask = async (params: {
  taskId: string;
  reply: string;
}): Promise<{ success: true }> => {
  try {
    const response = await axios.post(
      `/api/v1/tasker/complete/retweet-with-comment/${params.taskId}`,
      {
        reply: params.reply,
      },
    );
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const completeLikeTask = async (params: { taskId: string }): Promise<{ success: true }> => {
  try {
    const response = await axios.post(`/api/v1/tasker/complete/like/${params.taskId}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendNow = async (params: { taskerId: string }): Promise<IAirdropTaskerDetails> => {
  try {
    const response = await axios.post(`/api/v1/tasker/send-now/${params.taskerId}`);
    return response.data.data as IAirdropTaskerDetails;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeTasker = async (params: {
  taskerId: string;
}): Promise<IAirdropTaskerDetails> => {
  try {
    const response = await axios.delete(`/api/v1/tasker/remove/${params.taskerId}`);
    return response.data.data as IAirdropTaskerDetails;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const adminAirdropTaskers = async (
  page: number,
  count: number,
  search: string,
  orderBy?: string,
  orderDirection?: number,
) => {
  try {
    const response = await axios.get('/api/v1/tasker/taskers', {
      params: { page, count, search, orderBy, orderDirection },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const replyWithAI = async (taskId: string): Promise<{ message: string }> => {
  try {
    const response = await axios.get(`/api/v1/tasker/reply-with-ai/${taskId}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

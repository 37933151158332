import axios from 'axios';

export const airdropTypes = async () => {
  try {
    const response = await axios.get('/api/v1/airdrop-types');
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAirdropTypes = async (data: any) => {
  try {
    const response = await axios.post('/api/v1/airdrop-types', data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAirdropType = async (id: string) => {
  try {
    const response = await axios.delete(`/api/v1/airdrop-types/${id}`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAirdropType = async (id: string, data: any) => {
  try {
    const response = await axios.patch(`/api/v1/airdrop-types/${id}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

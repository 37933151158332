import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types/RootState';
import { userInitialState, UserStateType } from '@stores/slices/user.slice';

const selectUserDomain = (state: RootState) => state.user || userInitialState;

export const selectUser = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.user,
);

export const selectUserStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.status,
);

export const selectUserError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.error,
);

export const selectPersonalInfoStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.personalInfoStatus,
);

export const selectPersonalInfoError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.personalInfoError,
);

export const selectDeleteAccountStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.deleteAccountStatus,
);

export const selectDeleteAccountError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.deleteAccountError,
);

export const selectChangePasswordStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.changePasswordStatus,
);

export const selectChangePasswordError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.changePasswordError,
);

export const selectChangeEmailStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.changeEmailStatus,
);

export const selectChangeEmailError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.changeEmailError,
);

export const selectApproveEmailStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.approveEmailStatus,
);

export const selectApproveEmailError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.approveEmailError,
);

export const selectRevertEmailStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.revertEmailStatus,
);

export const selectRevertEmailError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.revertEmailError,
);

export const selectSetPasswordStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.setPasswordStatus,
);

export const selectSetPasswordError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.setPasswordError,
);

export const selectPreferencesModalIsVisible = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.preferencesModalIsVisible,
);

export const selectPreferencesStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.preferencesStatus,
);

export const selectPreferences = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.preferences,
);

export const selectCookieIsEnabled = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.selectCookieIsEnabled,
);

export const selectCookieBannerIsShown = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.cookieBannerIsShown,
);

export const selectWallets = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.wallets,
);

export const selectWalletsStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.walletsStatus,
);

export const selectKycSession = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.kycSession,
);

export const selectKycSessionStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.kycSessionStatus,
);

export const selectVerifyKycStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.verifyKycStatus,
);

export const selectCheckClaimAddressStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.checkClaimAddressStatus,
);

export const selectCheckClaimAddressError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.checkClaimAddressError,
);

export const selectAddClaimAddressStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.addClaimAddressStatus,
);

export const selectAddClaimAddressError = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.addClaimAddressError,
);

export const selectUserTaskerData = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.taskerData,
);

export const selectUserTaskerStatus = createSelector(
  [selectUserDomain],
  (userState: UserStateType) => userState.taskerStatus,
);

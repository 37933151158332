/* eslint-disable no-unused-vars */
import React from 'react';

import classes from './TulipCardExpired.module.scss';

interface TulipClaimCardProps {
  title?: React.ReactNode;
}

const TulipCardExpired: React.FC<TulipClaimCardProps> = ({ title }) => {
  return (
    <div className={classes.card}>
      <h2 className={classes.cardTitle}>{title}</h2>
    </div>
  );
};

export default TulipCardExpired;

import axios from 'axios';
import { AddSubscriberModal } from '../../types/subscribers';

export const addSubscriber = async (subscriber: AddSubscriberModal): Promise<any> => {
  try {
    const response = await axios.post('/api/v1/subscribers', subscriber);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const countSubscriber = async (fromDate?: string, toDate?: string) => {
  try {
    const response = await axios.get('/api/v1/subscribers/count', {
      params: {
        fromDate,
        toDate,
      },
    });
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createOptIn = async (email: string): Promise<any> => {
  try {
    const response = await axios.get(`/api/v1/subscribers/opt-in`, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types/RootState';
import { subscribersInitialState, SubscribersStateType } from '@stores/slices/subscribers.slice';

const selectSubscribersDomain = (state: RootState) => state.subscribers || subscribersInitialState;

export const selectSubscribersStatus = createSelector(
  [selectSubscribersDomain],
  (subscribersState: SubscribersStateType) => subscribersState.status,
);

export const selectSubscribersEmail = createSelector(
  [selectSubscribersDomain],
  (subscribersState: SubscribersStateType) => subscribersState.email,
);

export const selectSubscribersError = createSelector(
  [selectSubscribersDomain],
  (subscribersState: SubscribersStateType) => subscribersState.error,
);

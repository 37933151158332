/* eslint-disable no-unused-vars */
import { subscribersSaga } from '@stores/sagas/subscribers.saga';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { AddSubscriberModal } from '../../types/subscribers';

export interface SubscribersStateType {
  status: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  email: string;
  error: any;
}

export const subscribersInitialState: SubscribersStateType = {
  status: 'IDLE',
  email: '',
  error: null,
};

export const subscribersSlice = createSlice({
  name: 'subscribers',
  initialState: subscribersInitialState,
  reducers: {
    addSubscriber(state: SubscribersStateType, action: PayloadAction<AddSubscriberModal>) {
      state.status = 'LOADING';
      state.email = action.payload.email;
    },
    addSubscriberSuccess(state: SubscribersStateType) {
      state.status = 'SUCCESS';
    },
    addSubscriberFail(state: SubscribersStateType, action: PayloadAction<{ error: any }>) {
      state.status = 'ERROR';
      state.error = action.payload.error;
    },
    setState(state: SubscribersStateType, action: PayloadAction<Partial<SubscribersStateType>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset() {
      return subscribersInitialState;
    },
  },
});

export const { actions: subscribersActions, reducer } = subscribersSlice;

export const useSubscribersSlice = () => {
  useInjectReducer({ key: subscribersSlice.name, reducer: subscribersSlice.reducer });
  useInjectSaga({ key: subscribersSlice.name, saga: subscribersSaga });

  return { actions: subscribersActions };
};

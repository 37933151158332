import React from 'react';

import classes from './SuspenseFallback.module.scss';

const SuspenseFallback = () => {
  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SuspenseFallback;

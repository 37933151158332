import classNames from 'classnames';
import { ReactNode } from 'react';
import { ReactComponent as Check2Icon } from '../../../assets/images/check2.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/alert-info.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/error2.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info2.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import classes from './Toast.module.scss';

export declare type ToastType = 'info' | 'success' | 'error' | 'warning';

interface ToastProps {
  message: string | ReactNode;
  type: ToastType;
}

const Toast = (props: ToastProps) => {
  const containerClassName = classNames(classes.container, {
    [classes.success]: props.type == 'success',
    [classes.error]: props.type == 'error',
    [classes.info]: props.type == 'info',
    [classes.warning]: props.type == 'warning',
  });

  const successElement = (
    <div>
      <Check2Icon className={classes.icon} />
      <span>Success!</span>
    </div>
  );

  const warningElement = (
    <div>
      <WarningIcon className={classes.icon} />
      <span>Warning</span>
    </div>
  );

  const errorElement = (
    <div>
      <ErrorIcon className={classes.icon} />
      <span>Error</span>
    </div>
  );

  const infoElement = (
    <div>
      <InfoIcon className={classes.icon} />
      <span>Info</span>
    </div>
  );

  return (
    <div className={containerClassName}>
      <div className={classes.flex}>
        <div className={classes.header}>
          {props.type == 'success' && successElement}
          {props.type == 'warning' && warningElement}
          {props.type == 'error' && errorElement}
          {props.type == 'info' && infoElement}
          <CloseIcon className={classes.closeIcon} />
        </div>
        <p className={classes.message}>{props.message}</p>
      </div>
    </div>
  );
};

export default Toast;

import React, { useMemo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import './MainMenu.styles.scss';
import logoLight from '../../../../../assets/images/logo-light.svg';
import telegramIcon from '../../../../../assets/images/telegram.svg';
import twitterIcon from '../../../../../assets/images/twitter.svg';
import AppButton from '@components/atoms/AppButton/AppButton';
import { UserRole } from '../../../../../utils/main.utils';
import { useSelector } from 'react-redux';
import { selectAuthRole, selectAuthToken } from '@stores/selectors/auth.selector';
import { createBreakpoint } from 'react-use';
import GoogleTranslate from '@components/molecules/GoogleTranslate/GoogleTranslate';

/**
 * MainMenu component description
 */

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const useBreakpoint = createBreakpoint({
  desktop: 1199,
  laptop: 1024,
  tablet: 992,
  mobileL: 576,
  mobileM: 320,
  mobileS: 0,
});

function MainMenu({ isOpen, onClose }: Props) {
  const navigate = useNavigate();
  const userRole = useSelector(selectAuthRole);
  const userToken = useSelector(selectAuthToken);
  const isAuthenticated =
    userToken && userRole !== null && [UserRole.USER, UserRole.ADMIN].includes(userRole);

  // @ts-ignore
  const breakpoint: 'desktop' | 'tablet' | 'mobile' = useBreakpoint();

  const menuElements = useMemo(
    () => [
      {
        id: 1,
        label: 'Home',
        to: '/',
      },
      // {
      //   id: 2,
      //   label: 'Become Partner',
      //   to: '/rewards',
      // },
      {
        id: 3,
        label: 'Tasker',
        to: '/tasker',
      },
      // {
      //   id: 4,
      //   label: 'Affiliate',
      //   to: '/affiliate-program',
      // },
      {
        id: 5,
        label: 'Contact',
        to: '/contact-us',
      },
      {
        id: 6,
        label: 'FAQ',
        to: '/faq',
      },
      {
        id: 7,
        label: 'About',
        to: '/about-us',
      },
      // {
      //   id: 8,
      //   label: 'Bounty',
      //   to: '/airdrop-bounty',
      // },
      {
        id: 9,
        label: 'Cookie Policy',
        to: '/cookie-policy',
      },
      {
        id: 10,
        label: 'Terms & Conditions',
        to: '/terms-and-conditions',
      },
      {
        id: 11,
        label: 'Privacy Policy',
        to: '/privacy-policy',
      },
    ],
    [],
  );

  const className = useMemo(() => {
    const classes = ['main-menu-container'];
    isOpen && classes.push('open');

    return classes.join(' ');
  }, [isOpen]);

  return (
    <div data-testid="main-menu-container" className={className}>
      <div
        data-testid={'main-menu-overlay'}
        className={'main-menu-overlay'}
        onClick={onClose}
        aria-hidden
      >
        <div
          aria-hidden
          className={'main-menu'}
          data-testid="main-menu"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="main-menu-section">
            <span onClick={onClose} aria-hidden>
              <NavLink to={'/'}>
                <img
                  src={logoLight}
                  alt="logo"
                  width={120}
                  height={36}
                  className={'main-menu-logo'}
                />
              </NavLink>
            </span>
            {!isAuthenticated && (
              <AppButton
                variant={'primary'}
                text={'Log In'}
                className={'w-100 main-menu-login-button'}
                onClick={() => {
                  navigate('/login');
                }}
              />
            )}
            <ul className={'main-menu-list'}>
              {menuElements.map((element) => {
                return element.id === 5 ? (
                  <li key={element.id} onClick={onClose} aria-hidden>
                    <a
                      href="https://www.iubenda.com/privacy-policy/27468613/cookie-policy"
                      className="show-animation iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                      title="Cookie Policy "
                    >
                      Cookie Policy
                    </a>
                  </li>
                ) : (
                  <li key={element.id} onClick={onClose} aria-hidden>
                    <NavLink to={element.to}>{element.label}</NavLink>
                  </li>
                );
              })}
            </ul>
            {['mobileM', 'mobileS'].includes(breakpoint) && (
              <div className="translate">
                <GoogleTranslate className="w-100 mb-3" />
              </div>
            )}
            <AppButton
              variant={'primary'}
              text={'Newsletter'}
              className={'w-100 mb-3'}
              onClick={() => {
                navigate('/newsletter');
                onClose?.();
              }}
            />
          </div>
          <div className="main-menu-footer">
            <span className={'main-menu-footer-text'}>Find Us On</span>
            <span className={'main-menu-footer-social'}>
              <Link to="/telegram-group" target={'_blank'}>
                <img src={telegramIcon} alt="telegram" width={24} height={24} />
              </Link>
              <a
                href="https://twitter.com/AIRDROP__COM"
                target={'_blank'}
                rel="nofollow noreferrer"
              >
                <img src={twitterIcon} alt="twitter" width={24} height={24} />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainMenu;

import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import classes from './EnableCookies.module.scss';
import arrowIcon from '@assets/images/arrow-up.svg';
import { useUserSlice } from '@stores/slices/user.slice';
import { ReactComponent as CookieIcon } from '@assets/images/cookie.svg';
import { selectCookieBannerIsShown, selectCookieIsEnabled } from '@stores/selectors/user.selector';

const EnableCookies: React.FC = () => {
  const dispatch = useDispatch();
  const { actions: userActions } = useUserSlice();
  const cookieIsEnabled = useSelector(selectCookieIsEnabled);
  const isShown = useSelector(selectCookieBannerIsShown);

  const onToggleHandler = useCallback(() => {
    dispatch(userActions.toggleCookieBannerIsShown(!isShown));
  }, [isShown, dispatch, userActions]);

  const checkCookiesEnabledState = useCallback(() => {
    if (window.navigator.cookieEnabled) {
      dispatch(userActions.toggleCookieIsEnabled(true));
      dispatch(userActions.toggleCookieBannerIsShown(false));
    } else {
      dispatch(userActions.toggleCookieIsEnabled(false));
    }
  }, [dispatch, userActions]);

  const windowFocusHandler = useCallback(() => {
    checkCookiesEnabledState();
  }, [checkCookiesEnabledState]);

  useEffect(() => {
    checkCookiesEnabledState();
    window.addEventListener('focus', windowFocusHandler);

    return () => {
      window.removeEventListener('focus', windowFocusHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return cookieIsEnabled ? null : (
    <div
      data-testid={'enable-cookies'}
      className={classNames(classes.enableCookies, { [classes.show]: isShown })}
    >
      <Collapse in={isShown}>
        <div>
          <div className={classes.enableCookiesBig}>
            <figure className={classes.cookie}>
              <CookieIcon fill={'#DC0000'} width={80} height={80} />
            </figure>
            <div className={classes.information}>
              <h1 className={classes.title}>
                Cookies are <span className={classes.highLight}>disabled</span>
              </h1>
              <p className={classes.description}>
                With cookies disabled, the website would not function properly. <br />
                Make sure your cookies are enabled and try again.{' '}
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=en"
                  target={'_blank'}
                  rel={'nofollow noreferrer'}
                >
                  Learn more
                </a>
              </p>
            </div>
          </div>
        </div>
      </Collapse>

      <Collapse in={!isShown} className={classes.collapse}>
        <div>
          {!isShown && (
            <div className={classes.enableCookiesSmall}>
              <p className={classes.title}>
                Cookies are disabled.{' '}
                <a
                  href="https://support.google.com/accounts/answer/61416?hl=en"
                  target={'_blank'}
                  rel={'nofollow noreferrer'}
                >
                  Learn more
                </a>
              </p>
            </div>
          )}
        </div>
      </Collapse>

      <span aria-hidden className={classes.toggle} onClick={onToggleHandler}>
        <span className={classes.toggleText}>{isShown ? 'Hide' : 'Show'}</span>
        <span className={classes.toggleIcon}>
          <img src={arrowIcon} alt="arrow" />
        </span>
      </span>
    </div>
  );
};

export default EnableCookies;

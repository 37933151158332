import { airdropApi } from '../../api/airdrop';
import { authActions } from '@stores/slices/auth.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { UserRole } from '../../utils/main.utils';
import {
  LoginUserModel,
  LoginResponseModel,
  SetMewPasswordModel,
  ForgetPasswordModel,
  RegisterNewUserModel,
} from '../../types/auth';

export function* login({ payload }: PayloadAction<LoginUserModel>): Generator<any> {
  try {
    // FIXME: Type of token should be correct
    const auth = yield call(airdropApi.auth.Login, payload);
    yield put(authActions.loginSuccess(auth as LoginResponseModel));
  } catch (error: any) {
    // FIXME: Type of error should be correct
    yield put(authActions.loginFail({ error: error?.response?.data }));
  }
}

export function* register({ payload }: PayloadAction<RegisterNewUserModel>): Generator<any> {
  try {
    // FIXME: Type of token should be correct
    yield call(airdropApi.auth.register, payload);
    yield put(authActions.registerSuccess());
  } catch (error: any) {
    // FIXME: Type of error should be correct
    yield put(authActions.registerFail({ error: error?.response?.data }));
  }
}

export function* approveEmail({ payload }: PayloadAction<{ token: string }>): Generator<any> {
  try {
    const auth = yield call(airdropApi.auth.approveEmail, payload);
    yield put(authActions.approveEmailSuccess(auth as LoginResponseModel));
  } catch (error: any) {
    yield put(authActions.approveEmailError({ error }));
  }
}

export function* approveForgetEmail({ payload }: PayloadAction<{ token: string }>): Generator<any> {
  try {
    yield call(airdropApi.auth.approveForgetEmail, payload);
    yield put(authActions.approveForgetEmailSuccess());
  } catch (error: any) {
    yield put(authActions.approveForgetEmailFail({ error }));
  }
}

export function* resendVerificationLink({
  payload,
}: PayloadAction<{ token: string; redirectUrl?: string }>): Generator<any> {
  try {
    yield call(airdropApi.auth.resendVerificationLink, payload);
  } catch (error: any) {
    console.log(error);
  }
}

export function* forgetPassword({ payload }: PayloadAction<ForgetPasswordModel>): Generator<any> {
  try {
    // FIXME: Type of token should be correct
    yield call(airdropApi.auth.forgetPassword, payload);
    yield put(authActions.forgetPasswordSuccess());
  } catch (error: any) {
    // FIXME: Type of error should be correct
    yield put(authActions.forgetPasswordFail({ error: error?.response?.data }));
  }
}

export function* setNewPassword({ payload }: PayloadAction<SetMewPasswordModel>): Generator<any> {
  try {
    // FIXME: Type of token should be correct
    yield call(airdropApi.auth.setNewPassword, payload);
    yield put(authActions.setNewPasswordSuccess());
  } catch (error: any) {
    // FIXME: Type of error should be correct
    yield put(authActions.setNewPasswordFail({ error: error?.response?.data }));
  }
}

export function* logout(): Generator<any> {
  try {
    yield call(airdropApi.auth.logout);
    // eslint-disable-next-line no-empty
  } catch (error: any) {}
}

export function* twoFaAuthenticate({ payload }: PayloadAction<{ code: string }>): Generator<any> {
  try {
    const auth = yield call(airdropApi.auth.twoFaAuthenticate, payload.code);
    yield put(authActions.validateTwoFACodeSuccess(auth as { token: string; role: UserRole }));
  } catch (error: any) {
    yield put(authActions.validateTwoFACodeFail({ error: error?.response?.data }));
  }
}

export function* confirmGoogle({
  payload,
}: PayloadAction<{ code: string; referralCode: string }>): Generator<any> {
  try {
    // @ts-ignore
    const data: { data: { token: string; role: UserRole }; meta?: { register: boolean } } =
      yield call(airdropApi.auth.confirmGoogle, payload.code, payload.referralCode);
    const auth = { ...data.data, register: false };
    auth.register = !!data?.meta?.register;

    yield put(
      authActions.confirmGoogleSuccess(
        auth as { token: string; role: UserRole; register: boolean },
      ),
    );
  } catch (error: any) {
    yield put(authActions.confirmGoogleFail({ error: error?.response?.data }));
  }
}

export function* confirmFacebook({
  payload,
}: PayloadAction<{ code: string; referralCode?: string }>): Generator<any> {
  try {
    // @ts-ignore
    const data: { data: { token: string; role: UserRole }; meta?: { register: boolean } } =
      yield call(airdropApi.auth.confirmFacebook, payload.code, payload.referralCode);
    const auth = { ...data.data, register: false };
    auth.register = !!data?.meta?.register;

    yield put(
      authActions.confirmFacebookSuccess(
        auth as { token: string; role: UserRole; register: boolean },
      ),
    );
  } catch (error: any) {
    yield put(authActions.confirmFacebookFail({ error: error?.response?.data }));
  }
}

export function* authSaga(): Generator<any> {
  yield takeLatest(<any>authActions.login, login);
  yield takeLatest(<any>authActions.register, register);
  yield takeLatest(<any>authActions.approveEmail, approveEmail);
  yield takeLatest(<any>authActions.approveForgetEmail, approveForgetEmail);
  yield takeLatest(<any>authActions.resendVerificationLink, resendVerificationLink);
  yield takeLatest(<any>authActions.forgetPassword, forgetPassword);
  yield takeLatest(<any>authActions.setNewPassword, setNewPassword);
  yield takeLatest(<any>authActions.logout, logout);
  yield takeLatest(<any>authActions.validateTwoFACode, twoFaAuthenticate);
  yield takeLatest(<any>authActions.confirmGoogle, confirmGoogle);
  yield takeLatest(<any>authActions.confirmFacebook, confirmFacebook);
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './api/axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import logo from './assets/images/only-logo.png';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureAppStore from './stores/store';
import { loadState } from './local-storage';
import { AuthStateType } from '@stores/slices/auth.slice';
import { Ethereum, Goerli } from '@thirdweb-dev/chains';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  getIsMetaMaskWallet,
  getIsGenericInjector,
  getShouldAdvertiseMetaMask,
} from './utils/main.utils';
import {
  ChainId,
  walletConnect,
  metamaskWallet,
  coinbaseWallet,
  ThirdwebProvider,
} from '@thirdweb-dev/react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Load state from LocalStorage to redux
const persistedState: { auth: AuthStateType } | undefined = loadState();
if (persistedState?.auth?.status === 'LOADING') {
  persistedState.auth.status = 'UNREGISTER';
}
if (
  persistedState?.auth?.registerStatus === 'LOADING' ||
  persistedState?.auth?.registerStatus === 'ERROR'
) {
  persistedState.auth.registerStatus = 'IDLE';
}
const store = configureAppStore(persistedState);

const NETWORKS = {
  goerli: {
    name: 'Goerli',
    chain: Goerli,
    chainId: ChainId.Goerli,
  },
  ethereum: {
    name: 'Mainnet',
    chain: Ethereum,
    chainId: ChainId.Mainnet,
  },
};
export const NETWORK = NETWORKS['ethereum'];

const wallets: any = [coinbaseWallet(), walletConnect()];
const shouldDisplayMetamask =
  getIsMetaMaskWallet() || getShouldAdvertiseMetaMask() || getIsGenericInjector();
if (shouldDisplayMetamask) wallets.push(metamaskWallet());

// FIXME: ENABLE STRICKMODE AND CKECK EDITOR COMPONENT
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={'6LdtI1IiAAAAAOOQq_Lu7QNGF4TRxWQQsnCKFnOu'}
      useEnterprise={true}
      scriptProps={{
        async: true,
      }}
    >
      <ThirdwebProvider
        clientId={process.env.REACT_APP_THIRD_WEB_CLIENT_ID}
        secretKey={process.env.REACT_APP_THIRD_WEB_SECRET_KEY}
        supportedWallets={wallets}
        activeChain={NETWORK.chain}
        dAppMeta={{
          name: 'Airdrop',
          description: 'Airdrop: Discover the best crypto airdrops',
          logoUrl: logo,
          url: 'https://airdrop.com',
          isDarkMode: false,
        }}
      >
        <App />
      </ThirdwebProvider>
    </GoogleReCaptchaProvider>
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from 'axios';

export const airdropStatus = async () => {
  try {
    const response = await axios.get('/api/v1/airdrop-statuses');
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAirdropStatus = async (data: any) => {
  try {
    const response = await axios.post('/api/v1/airdrop-statuses', data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAirdropStatus = async (id: string) => {
  try {
    const response = await axios.delete(`/api/v1/airdrop-statuses/${id}`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAirdropStatus = async (id: string, data: any) => {
  try {
    const response = await axios.patch(`/api/v1/airdrop-statuses/${id}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

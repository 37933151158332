import React from 'react';
import Header from '@components/Layouts/AppPage/components/Header/Header';
import './AppPage.styles.scss';
import Footer from './components/Footer/Footer';
import { Helmet } from 'react-helmet';

/**
 * AppPage component description
 */

// Props to be passed to the component
type AppPageProps = {
  children: JSX.Element | JSX.Element[];
  keepHeaderSpace?: boolean;
  hideFooter?: boolean;
};

// Rendering the page
const AppPage: React.FC<AppPageProps> = ({ children, keepHeaderSpace, hideFooter }) => (
  <div className="AppPage">
    <Helmet>
      <title>Airdrop.com - Receive The Best Crypto Airdrops</title>
      <meta
        name="description"
        content="The best free resource where you can find the latest crypto airdrops. Participate in DeFi airdrops, contests, raffles, giveaways and earn tokens, NFTs, POAPs."
      />
    </Helmet>
    <Header keepSpace={keepHeaderSpace} />
    <div className="AppPageBody" data-testid="body">
      {children}
    </div>
    {!hideFooter && <Footer />}
  </div>
);

export default AppPage;

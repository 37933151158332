import { createSelector } from '@reduxjs/toolkit';
import { authInitialState, AuthStateType } from '@stores/slices/auth.slice';
import { RootState } from '../../types/RootState';

// First select the relevant part from the state
const selectAuthDomain = (state: RootState) => state.auth || authInitialState;

export const selectAuthStatus = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.status,
);

export const selectAuthError = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.error,
);

export const selectAuthToken = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.token,
);

export const selectAuthRole = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.role,
);

export const selectAuthQrCode = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.qrCode,
);

export const selectRegisterStatus = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.registerStatus,
);

export const selectRegisterEmail = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.registerEmail,
);

export const selectRegisterError = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.registerError,
);

export const selectRecoverStatus = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.recoverStatus,
);

export const selectRecoverEmail = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.recoverEmail,
);

export const selectRecoverError = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.recoverError,
);

export const selectIsSocialRegister = createSelector(
  [selectAuthDomain],
  (authState: AuthStateType) => authState.isSocialRegister,
);

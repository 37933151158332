import React, { useCallback, useMemo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import classes from './TulipGenericPageExpired.module.scss';
import tulipImage from '../../assets/images/tulip/tulip.png';
import AppPage from '@components/Layouts/AppPage/AppPage';
import AppButton from '@components/atoms/AppButton/AppButton';
import { ReactComponent as BackIcon } from '../../assets/images/triangle-left.svg';
import TulipCardExpired from '@components/molecules/TulipCardExpired/TulipCardExpired';

const TulipGenericPageExpired = () => {
  const navigate = useNavigate();

  const onBackHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const heroMain = useMemo(() => {
    return (
      <>
        <h1 className={classes.title}>
          Most Ethereum addresses are eligible for airdrop of $LAND token.
        </h1>
        <div className={classes.description}>
          <a href="https://app.uniswap.org/#/swap?inputCurrency=0xdAC17F958D2ee523a2206206994597C13D831ec7&outputCurrency=0x3470620864b98c8a69091fc31660359E10313E8F">
            $LAND
          </a>{' '}
          token can be staked to participate to the daily lottery of Tulip NFT.
        </div>
        <p className={classes.description}>
          Want to know more about Tulip NFTs? Visit <a href="https://tulipart.com">TulipArt.com</a>.
        </p>
        <div className={classes.video}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/48fPAzeqcLg?rel=0&modestbranding=1&controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </>
    );
  }, []);

  return (
    <AppPage keepHeaderSpace={true}>
      <div className={classNames(classes.wrapper, {})}>
        <div className={classes.hero}>
          <div className={classes.heroHeaderTop}>
            <AppButton
              variant={'lightLink'}
              text={'Back'}
              leftIcon={<BackIcon />}
              onClick={onBackHandler}
            />
          </div>
          <div className={classes.container}>
            <div className={classes.heroMain}>{heroMain}</div>
            <figure className={classes.tulipImage}>
              <img src={tulipImage} alt="tulip" />
            </figure>
          </div>
        </div>

        <TulipCardExpired title={'This AirDrop has expired!'} />
      </div>
    </AppPage>
  );
};

export default TulipGenericPageExpired;

// eslint-disable-next-line no-unused-vars
import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createInjectorsEnhancer } from 'redux-injectors';
import { createReducer } from './reducers';
import { throttle } from 'lodash';
import { saveState } from '../local-storage';
// import { whitelistInitialState } from './slices/whitelist.slice';

export default function configureAppStore(preloadedState = {}) {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  // Middleware and store enhancers
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  if (process.env.NODE_ENV !== 'production') {
    // enhancers.push(applyMiddleware(createLogger()));
  }

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
    preloadedState: preloadedState,
  });

  // store auth state in LocalStorage
  store.subscribe(
    throttle(() => {
      saveState({ auth: store.getState()?.auth });
    }, 800),
  );
  (<any>window).store = store;

  return store;
}

import axios from 'axios';
import { CreateWalletModel, EditWalletModel, Wallet } from '../../types/wallet';
import {
  UserModel,
  IKYCSession,
  Preferences,
  UserReferrals,
  UserReferralAward,
} from '../../types/user';
import { IAdminAirdropTaskerDetails } from '../../types/tasker';

export const getUser = async (): Promise<UserModel> => {
  try {
    const response = await axios.get('/api/v1/users/me');
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserPersonalInfo = async (
  user: Pick<UserModel, 'firstName' | 'lastName'>,
): Promise<UserModel> => {
  try {
    const response = await axios.patch('/api/v1/users/me', {
      firstName: user.firstName,
      lastName: user.lastName,
    });
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUserPersonalInfo2 = async (
  user: Pick<UserModel, 'firstName' | 'lastName' | 'picture'>,
): Promise<UserModel> => {
  try {
    const formData = new FormData();
    Object.keys(user).forEach((key) => {
      // @ts-ignore
      formData.append(key, user[key]);
    });

    const response = await axios.patch('/api/v2/users/me', formData);
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAccount = async ({ password }: { password: string }): Promise<any> => {
  try {
    const response = await axios.delete('/api/v1/users/me', {
      data: {
        password,
      },
    });
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changePassword = async ({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}): Promise<any> => {
  try {
    const response = await axios.patch('/api/v1/users/change-password', {
      password: password,
      newPassword: newPassword,
    });
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeEmail = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<any> => {
  try {
    const response = await axios.post('/api/v1/users/change-email', {
      password: password,
      newEmail: email,
    });
    return response.data.data as UserModel;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const approveEmail = async (payload: { token: string }): Promise<string | null> => {
  try {
    let response = await axios.post(
      '/api/v1/users/change-email/confirm-email',
      {},
      {
        headers: {
          confirmationToken: payload.token,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const revertEmail = async (payload: { token: string }): Promise<string | null> => {
  try {
    let response = await axios.post(
      '/api/v1/users/change-email/revert-email',
      {},
      {
        headers: {
          revertToken: payload.token,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const setPassword = async ({ password }: { password: string }): Promise<any> => {
  try {
    const response = await axios.patch('/api/v1/users/set-password', {
      password: password,
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const users = async (
  page: number,
  count: number,
  search?: string,
  orderBy?: string,
  orderDirection?: number,
  fromDate?: string,
  toDate?: string,
) => {
  try {
    const response = await axios.get('/api/v1/users', {
      params: {
        page,
        count,
        search,
        orderBy,
        orderDirection,
        fromDate,
        toDate,
      },
    });
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const specificUser = async (userId: string) => {
  try {
    const response = await axios.get(`/api/v1/users/${userId}`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateSpecificUser = async (userId: string, data: { isReferralEnabled: boolean }) => {
  try {
    const response = await axios.patch(`/api/v1/users/${userId}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const referrals = async (userId: string): Promise<UserReferrals> => {
  try {
    const response = await axios.get(`/api/v1/users/${userId}/referrals`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const myTaskerDetails = async (): Promise<IAdminAirdropTaskerDetails> => {
  try {
    const response = await axios.get(`/api/v1/users/me/taskers`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const taskerDetails = async (userId: string): Promise<IAdminAirdropTaskerDetails> => {
  try {
    const response = await axios.get(`/api/v1/users/${userId}/taskers`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setReferrals = async (
  userId: string,
  referralId: string,
  data: {
    user: number;
    usd: number;
  },
): Promise<UserReferrals> => {
  try {
    const response = await axios.patch(`/api/v1/users/${userId}/referrals/${referralId}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const referralAwards = async (userId: string): Promise<UserReferralAward[]> => {
  try {
    const response = await axios.get(`/api/v1/transactions/user/${userId}/referral-awards`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addReferralAward = async (
  userId: string,
  data: { amount: number },
): Promise<UserReferralAward> => {
  try {
    const response = await axios.post(`/api/v1/transactions/user/${userId}/referral-awards`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteReferralAward = async (
  userId: string,
  referralAwardId: string,
): Promise<any> => {
  try {
    const response = await axios.delete(
      `/api/v1/transactions/user/${userId}/referral-awards/${referralAwardId}`,
    );
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const countUser = async (params: {
  search?: string;
  fromDate?: string;
  toDate?: string;
  status?: string;
  withConfirmedWA?: boolean;
}) => {
  const { search, fromDate, toDate, status, withConfirmedWA } = params;
  try {
    const response = await axios.get('/api/v1/users/count', {
      params: {
        search,
        fromDate,
        toDate,
        status,
        withConfirmedWallet: withConfirmedWA,
      },
    });
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPreferences = async (): Promise<Preferences> => {
  try {
    const response = await axios.get(`/api/v1/preferences/me`);
    return response?.data?.data as Preferences;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setPreferences = async (data: Preferences): Promise<Preferences> => {
  try {
    const response = await axios.post(`/api/v1/preferences`, data);
    return response.data.data as Preferences;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWallets = async (): Promise<Wallet[]> => {
  try {
    const response = await axios.get(`/api/v1/users/me/wallets`);
    return response.data.data as Wallet[];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createWallet = async (data: CreateWalletModel): Promise<Wallet> => {
  try {
    const response = await axios.post(`/api/v1/users/me/wallets`, data);
    return response.data.data as Wallet;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editWallet = async (data: EditWalletModel): Promise<Wallet> => {
  try {
    const { walletId, ...body } = data;
    const response = await axios.patch(`/api/v1/users/me/wallets/${walletId}`, body);
    return response.data.data as Wallet;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getKycSession = async (): Promise<IKYCSession> => {
  try {
    const response = await axios.get(`/api/v1/users/kyc-session`);
    return response.data.data as IKYCSession;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyKyc = async (params: { reference: string }): Promise<IKYCSession> => {
  try {
    const response = await axios.post(`/api/v1/users/verify-kyc`, params);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkClaimAddress = async (address: string): Promise<IKYCSession> => {
  try {
    const response = await axios.get(`/api/v1/users/me/claim-wallets/${address}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addClaimAddress = async (body: {
  address: string;
  signedMessage: string;
  currencyTicker: string;
}): Promise<Wallet> => {
  try {
    const response = await axios.post(`/api/v1/users/me/claim-wallets`, body);
    return response.data.data as Wallet;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const connectTwitter = async (): Promise<{ url: string }> => {
  try {
    const response = await axios.get(`/api/v1/users/connect-x`);
    return response.data as { url: string };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const confirmConnectTwitter = async (params: {
  state: string;
  code: string;
}): Promise<{
  id: string;
  name: string;
  username: string;
}> => {
  try {
    const response = await axios.get(`/api/v1/users/connect-x/confirm`, {
      params,
    });
    return response.data.data as {
      id: string;
      name: string;
      username: string;
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const disconnectTwitter = async (): Promise<{ success: boolean }> => {
  try {
    const response = await axios.get(`/api/v1/users/disconnect-x`);
    return response.data.data as { success: boolean };
  } catch (error) {
    return Promise.reject(error);
  }
};

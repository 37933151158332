import React, { FC } from 'react';
import classNames from 'classnames';

import classes from './ReCaptchaInfo.module.scss';

interface ReCaptchaInfoProps {
  containerClassname?: string;
  textClassName?: string;
}

const ReCaptchaInfo: FC<ReCaptchaInfoProps> = ({ textClassName, containerClassname }) => {
  return (
    <div className={classNames([classes.container, containerClassname])}>
      <span className={classNames([classes.text, textClassName])}>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>
    </div>
  );
};

export default ReCaptchaInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import classes from './NewsLetterForm2.module.scss';
import AppInput from '@components/atoms/AppInput/AppInput';
import AppButton from '@components/atoms/AppButton/AppButton';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSubscribersSlice } from '@stores/slices/subscribers.slice';
import { selectSubscribersStatus } from '@stores/selectors/subscribers.selector';
import { ReactComponent as EmailIcon } from '../../../assets/images/email.svg';

interface NewsLetterForm2Props {
  inputVariant?: 'gray' | 'white';
  onSuccess?: () => void;
  onError?: () => void;
}

const NewsLetterForm2: React.FC<NewsLetterForm2Props> = ({
  inputVariant = 'white',
  onSuccess,
  onError,
}) => {
  const dispatch = useDispatch();
  const subscribersStatus = useSelector(selectSubscribersStatus);
  const { actions: subscribersActions } = useSubscribersSlice();

  const { register, setValue, handleSubmit } = useForm<{ email: string }>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('');

  const onSubmit = ({ email }: { email: string }) => {
    if (subscribersStatus !== 'LOADING') {
      if (/^[A-z0-9._%+-]+@[A-z0-9.-]+.[A-z]{2,4}$/.test(email)) {
        dispatch(
          subscribersActions.addSubscriber({
            email: email,
          }),
        );
      } else {
        onError?.();
      }
    }
  };

  const handleVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    if (subscribersStatus === 'SUCCESS') {
      setValue('email', '');
      onSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribersStatus]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div data-testid={'mc-embedded-subscribe-form'} className={classes.mcFieldGroup}>
        <AppInput
          type="text"
          variant={inputVariant}
          className={classes.mcEmail}
          placeholder={'Enter your e-mail address'}
          icon={<EmailIcon />}
          {...register('email')}
        />
        <AppButton
          text={'Subscribe'}
          type={'submit'}
          variant={'success'}
          className={classes.mcEmbeddedSubscribe}
        />
      </div>

      <GoogleReCaptcha onVerify={handleVerify} />
    </Form>
  );
};

export default NewsLetterForm2;

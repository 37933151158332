import axios from 'axios';
import { handleApiRequestFailure } from '.';

type ApiResponse = {
  _id: string;
};

export type ApiFailure = {
  errors: { [fieldId: string]: string };
  message: string;
};

export const whitelistAddress = async (payload: { email: string }): Promise<ApiResponse | null> => {
  let response = null;
  try {
    response = await axios.post('/api/v1/users/subscribe', payload);
    switch (response.status) {
      case 201:
        return response.data;
      default:
        return Promise.reject(response.data);
    }
  } catch (error: any) {
    return handleApiRequestFailure(error);
  }
};

export const approveAddress = async (payload: { token: string }): Promise<ApiResponse | null> => {
  let response = null;
  try {
    response = await axios.post(
      '/api/v1/users/confirm-email',
      {},
      {
        headers: {
          confirmationToken: payload.token,
        },
      },
    );
    return response.data;
  } catch (error: any) {
    return handleApiRequestFailure(error);
  }
};

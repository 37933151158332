import axios from 'axios';
import {
  Airdrop,
  IReferrals,
  IClaimInfos,
  ISignedWallet,
  AirdropFormType,
  ClaimPartnerAirdrop,
  SubmitAirdropBountyModel,
  ICheckPartnerAirdropResponse,
  IClaimPartnerAirdropResponse,
  IAffiliateReport,
  IJoinToAffiliateProgram,
  IJoinToAffiliateProgramResponse,
  ICheckKYCAirdropResponse,
} from '../../types/airdrop';
import { v4 as uuidv4 } from 'uuid';
import { ITulipStats } from '@stores/slices/admin/airdrop/admin.airdrop.slice';
import { AirdropIssueFormType } from '../../types/AirdropIssue';
import { VoteType } from '../../utils/main.utils';
import { EstimatedTimeRange, EstimatedValueRange } from '../../types/AirdropFilter';

export const labeled = async (): Promise<string> => {
  try {
    const response = await axios.get('/api/v1/airdrop/labeled', {
      params: {
        // airdropStatusId: AirdropStatus.OPEN,
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const airdrops = async (
  page: number,
  count: number,
  search?: string,
  userTypes?: string[],
  orderBy?: string,
  orderDirection?: number,
  airdropStatusId?: string,
  favorites?: boolean,
  ecosystemIds?: string,
  airdropTypeIds?: string,
  fromEstimatedTimeToApply?: string,
  toEstimatedTimeToApply?: string,
  minEstimatedValue?: number,
  maxEstimatedValue?: number,
): Promise<string> => {
  try {
    // Pass same filter params in "nextAirdropBySlug" endpoint
    const response = await axios.get('/api/v1/airdrop', {
      params: {
        page,
        count,
        search,
        userTypes,
        orderBy,
        orderDirection,
        airdropStatusId,
        favorites: favorites,
        ...(!!ecosystemIds && { ecosystemIds }),
        ...(!!ecosystemIds && { ecosystemIds }),
        ...(!!airdropTypeIds && { airdropTypeIds }),
        ...(!!fromEstimatedTimeToApply && { fromEstimatedTimeToApply }),
        ...(!!toEstimatedTimeToApply && { toEstimatedTimeToApply }),
        ...((minEstimatedValue !== 0 || maxEstimatedValue !== 1000) &&
          (!!minEstimatedValue || minEstimatedValue === 0) && { minEstimatedValue }),
        ...((minEstimatedValue !== 0 || maxEstimatedValue !== 1000) &&
          (!!maxEstimatedValue || maxEstimatedValue === 0) && { maxEstimatedValue }),
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const airdropByID = async (id: string): Promise<string> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/${id}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const airdropBySlug = async (urlSlug: string): Promise<Airdrop> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/slug/${urlSlug}`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const nextAirdropBySlug = async (
  urlSlug: string,
  {
    page,
    search,
    orderBy,
    orderDirection,
    airdropStatusId,
    favorites,
    ecosystemIds,
    userTypes,
    airdropTypeIds,
    fromEstimatedTimeToApply,
    toEstimatedTimeToApply,
    minEstimatedValue,
    maxEstimatedValue,
  }: {
    page: number;
    search?: string;
    orderBy?: string;
    orderDirection?: number;
    airdropStatusId?: string;
    favorites?: boolean;
    ecosystemIds?: string;
    userTypes?: string[];
    airdropTypeIds?: string;
    fromEstimatedTimeToApply?: string;
    toEstimatedTimeToApply?: string;
    minEstimatedValue?: number;
    maxEstimatedValue?: number;
  },
): Promise<Airdrop> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/${urlSlug}/next`, {
      params: {
        page,
        search,
        userTypes,
        orderBy,
        orderDirection,
        airdropStatusId,
        favorites: favorites,
        ...(!!ecosystemIds && { ecosystemIds }),
        ...(!!ecosystemIds && { ecosystemIds }),
        ...(!!airdropTypeIds && { airdropTypeIds }),
        ...(!!fromEstimatedTimeToApply && { fromEstimatedTimeToApply }),
        ...(!!toEstimatedTimeToApply && { toEstimatedTimeToApply }),
        ...((minEstimatedValue !== 0 || maxEstimatedValue !== 1000) &&
          (!!minEstimatedValue || minEstimatedValue === 0) && { minEstimatedValue }),
        ...((minEstimatedValue !== 0 || maxEstimatedValue !== 1000) &&
          (!!maxEstimatedValue || maxEstimatedValue === 0) && { maxEstimatedValue }),
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAirdrop = async (data: AirdropFormType) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    const response = await axios.post('/api/v1/airdrop', data, config);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAirdrop = async (id: string, data: Partial<AirdropFormType>) => {
  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) (data as any)[key] = `[]`;
    });

    const response = await axios.patch(`/api/v1/airdrop/${id}`, data, config);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeAirdropViewsCount = async (id: string) => {
  if (window.navigator.cookieEnabled) {
    try {
      let viewToken = localStorage.getItem('viewToken');
      if (!viewToken) {
        viewToken = uuidv4();
        localStorage.setItem('viewToken', viewToken);
      }

      const response = await axios.post(`/api/v1/airdrop/view`, {
        airdropId: id,
        cookie: viewToken,
      });
      return response?.data?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export const isSlugAvailable = async (slug: string, ignoreAirdropId?: string) => {
  try {
    const response = await axios.get(`/api/v1/airdrop/slug-existence/${slug}`, {
      params: {
        ...(!!ignoreAirdropId && { ignoreAirdropId }),
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addToFavorite = async (id: string): Promise<string> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/${id}/favorites`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeFromFavorite = async (id: string): Promise<string> => {
  try {
    const response = await axios.delete(`/api/v1/airdrop/${id}/favorites`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const vote = async (id: string, voteType: VoteType): Promise<string> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/${id}/votes`, {
      voteType,
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeVote = async (id: string): Promise<string> => {
  try {
    const response = await axios.delete(`/api/v1/airdrop/${id}/votes`);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitBounty = async (data: SubmitAirdropBountyModel): Promise<string> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/bounties`, data);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const estimatedValueRange = async (): Promise<EstimatedValueRange> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/estimated-value-range`, {
      params: {
        // airdropStatusId: AirdropStatus.OPEN,
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const estimatedTimeRange = async (): Promise<EstimatedTimeRange> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/estimated-time-range`, {
      params: {
        // airdropStatusId: AirdropStatus.OPEN,
      },
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const airdropIssues = async (
  page: number,
  count: number,
  search: string,
  fromDate: string,
  toDate: string,
  orderBy?: string,
  orderDirection?: number,
) => {
  try {
    const response = await axios.get('/api/v1/airdrop/issues', {
      params: { page, count, search, fromDate, toDate, orderBy, orderDirection },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAirdropIssue = async (airdropId: string, data: AirdropIssueFormType) => {
  try {
    const response = await axios.post(`/api/v1/airdrop/${airdropId}/issues`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkTulipPartnerAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<ICheckPartnerAirdropResponse> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/check/partner`, data);
    return response?.data?.data as ICheckPartnerAirdropResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkTulipKYCAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<ICheckKYCAirdropResponse> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/check/kyc`, data);
    return response?.data?.data as ICheckKYCAirdropResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimTulipPartnerAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<IClaimPartnerAirdropResponse> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/partner`, data);
    return response?.data?.data as IClaimPartnerAirdropResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimTulipKYCAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<IClaimPartnerAirdropResponse> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/kyc`, data);
    return response?.data?.data as IClaimPartnerAirdropResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimExosamaAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<{ referralCode: string }> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/exosama`, data);
    return response?.data?.data as { referralCode: string };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimEthAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<{ referralCode: string }> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/eth`, data);
    return response?.data?.data as { referralCode: string };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimMoonsamaAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<{ referralCode: string }> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/moonsama`, data);
    return response?.data?.data as { referralCode: string };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const claimTulipAirdrop = async (
  data: ClaimPartnerAirdrop,
): Promise<{ referralCode: string }> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/tulip`, data);
    return response?.data?.data as { referralCode: string };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const tulipStats = async (): Promise<ITulipStats> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/claim/count`);
    return response?.data?.data as ITulipStats;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getClaimInfos = async (): Promise<IClaimInfos[]> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/claim/info`);
    return response?.data as IClaimInfos[];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReferrals = async (): Promise<IReferrals> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/tulip/info`);
    return response?.data?.data as IReferrals;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAffiliateReport = async (): Promise<IAffiliateReport[]> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/tulip/affiliate-report`);
    return response?.data?.data as IAffiliateReport[];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTulipStats = async (): Promise<ITulipStats> => {
  try {
    const response = await axios.get(`/api/v1/airdrop/tulip/claimed`);
    return response?.data?.data as ITulipStats;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkSignedWallet = async (address: string): Promise<ISignedWallet> => {
  try {
    const response = await axios.get(`/api/v1/signed-wallets/signed/${address}`);
    return response.data.data as ISignedWallet;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addSignedWallet = async (body: {
  address: string;
  signedMessage: string;
  currencyTicker: string;
}): Promise<ISignedWallet> => {
  try {
    const response = await axios.post(`/api/v1/signed-wallets/sign`, body);
    return response.data.data as ISignedWallet;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const joinToAffiliateProgram = async (
  data: IJoinToAffiliateProgram,
): Promise<IJoinToAffiliateProgramResponse> => {
  try {
    const response = await axios.post(`/api/v1/airdrop/tulip/join-to-affiliate-program`, data);
    return response.data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

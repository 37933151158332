import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// import PassKYC from '@pages/PassKYC/PassKYC';
// import TulipKYC from '@pages/TulipKYC/TulipKYC';
// import VerifyKYC from '@pages/VerifyKYC/VerifyKYC';
// import TulipETH from '@pages/TulipETH/TulipETH';
import ScrollToTop from '@components/atoms/ScrollToTop/ScrollToTop';
import PrivateRoute from './PrivateRoute';
// import PreferencesModal from '@components/molecules/PreferencesModal/PreferencesModal';
import SuspenseFallback from '@components/atoms/SuspenseFallback/SuspenseFallback';
import { UserRole } from '../utils/main.utils';
// import { useSelector } from 'react-redux';
// import { selectAuthToken } from '@stores/selectors/auth.selector';
// import TulipGenericPage from '@pages/TulipGenericPage/TulipGenericPage';
import TulipGenericPageExpired from '@pages/TulipGenericPageExpired/TulipGenericPageExpired';

const LoggedInLayout = React.lazy(
  () => import('@components/Layouts/LoggedInLayout/LoggedInLayout'),
);
const LoginPage = React.lazy(() => import('../pages/LoginPage/LoginPage'));
const RegisterPage = React.lazy(() => import('@pages/RegisterPage/RegisterPage'));
const ConfirmEmail = React.lazy(() => import('../pages/ConfirmEmail/ConfirmEmail'));
const TermsAndConditions = React.lazy(() => import('@pages/TermsAndConditions/TermsAndConditions'));
const NotFound = React.lazy(() => import('@pages/NotFound/NotFound'));
const VerifyEmailSent = React.lazy(() => import('@pages/VerifyEmailSent/VerifyEmailSent'));
const HomePage = React.lazy(() => import('@pages/HomePage/HomePage'));
const Airdrop = React.lazy(() => import('@pages/admin/airdrop/airdrop'));
const ContactUs = React.lazy(() => import('@pages/ContactUs/ContactUs'));
const FAQ = React.lazy(() => import('@pages/FAQ/FAQ'));
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy/PrivacyPolicy'));
const Newsletter = React.lazy(() => import('../pages/Newsletter/Newsletter'));
const AboutUs = React.lazy(() => import('../pages/AboutUs/AboutUs'));
const CookiePolicy = React.lazy(() => import('../pages/CookiePolicy/CookiePolicy'));
const CreateAirdrop = React.lazy(() => import('@pages/admin/CreateAirdrop/CreateAirdrop'));
const AirdropMobileDetail = React.lazy(
  () => import('@pages/AirdropMobileDetail/AirdropMobileDetail'),
);
const EditAirdrop = React.lazy(() => import('@pages/admin/EditAirdrop/EditAirdrop'));
const ForgotPassword = React.lazy(() => import('@pages/ForgotPassword/ForgotPassword'));
const RecoverPasswordSent = React.lazy(
  () => import('@pages/RecoverPasswordSent/RecoverPasswordSent'),
);
const Profile = React.lazy(() => import('@pages/Profile/Profile'));
const RecoverPassword = React.lazy(() => import('@pages/RecoverPassword/RecoverPassword'));
const ConfirmChangeEmail = React.lazy(() => import('@pages/ConfirmChangeEmail/ConfirmChangeEmail'));
const RevertEmail = React.lazy(() => import('@pages/RevertEmail/RevertEmail'));

const TwoFAQrCode = React.lazy(() => import('@pages/TwoFAQrCode/TwoFAQrCode'));
const TwoFACode = React.lazy(() => import('@pages/TwoFACode/TwoFACode'));
const AirdropBounty = React.lazy(() => import('@pages/AirdropBounty/AirdropBounty'));
const ConfirmGoogleAccount = React.lazy(
  () => import('@pages/ConfirmGoogleAccount/ConfirmGoogleAccount'),
);
const ConfirmFacebookAccount = React.lazy(
  () => import('@pages/ConfirmFacebookAccount/ConfirmFacebookAccount'),
);
const ConfirmTwitterAccount = React.lazy(
  () => import('@pages/ConfirmTwitterAccount/ConfirmTwitterAccount'),
);
const Issue = React.lazy(() => import('@pages/admin/Issue/Issue'));
const TaskerAirdrops = React.lazy(() => import('@pages/admin/TaskerAirdrops/TaskerAirdrops'));
const SetPassword = React.lazy(() => import('@pages/SetPassword/SetPassword'));
const User = React.lazy(() => import('@pages/admin/user/user'));
const UserDetail = React.lazy(() => import('@pages/admin/UserDetail/UserDetail'));
const Dashboard = React.lazy(() => import('@pages/admin/Dashboard/Dashboard'));
const RedirectTo = React.lazy(() => import('@pages/RedirectTo/RedirectTo'));
const Rewards = React.lazy(() => import('@pages/Rewards/Rewards'));
const Tasker = React.lazy(() => import('@pages/Tasker/Tasker'));
const Referrals = React.lazy(() => import('@pages/Referrals/Referrals'));
const ReferralSignup = React.lazy(() => import('@pages/ReferralSignup/ReferralSignup'));
const Campaign = React.lazy(() => import('@pages/Campaign/Campaign'));
const TaskerWaitListPage = React.lazy(() => import('@pages/TaskerWaitListPage/TaskerWaitListPage'));
// const TulipExosamaAirdrop = React.lazy(
//   () => import('@pages/TulipExosamaAirdrop/TulipExosamaAirdrop'),
// );
// const TulipMoonsamaAirdrop = React.lazy(
//   () => import('@pages/TulipMoonsamaAirdrop/TulipMoonsamaAirdrop'),
// );
const AffiliateReport = React.lazy(() => import('@pages/AffiliateReport/AffiliateReport'));

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  // const userToken = useSelector(selectAuthToken);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<SuspenseFallback />}>
        <Routes>
          <Route path="/" element={<LoggedInLayout />}>
            <Route path="/admin">
              <Route index={true} element={<Navigate to="/admin/airdrops" replace />} />

              <Route path="airdrops">
                <Route
                  index={true}
                  element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<Airdrop />} />}
                />
                <Route
                  path="create"
                  element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<CreateAirdrop />} />}
                />
                <Route
                  path=":id/edit"
                  element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<EditAirdrop />} />}
                />
              </Route>

              <Route
                path="issues"
                element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<Issue />} />}
              />

              <Route
                path="taskers"
                element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<TaskerAirdrops />} />}
              />

              <Route path="users">
                <Route
                  index={true}
                  element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<User />} />}
                />

                <Route
                  path=":id"
                  element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<UserDetail />} />}
                />
              </Route>

              <Route
                path="dashboard"
                element={<PrivateRoute roles={[UserRole.ADMIN]} outlet={<Dashboard />} />}
              />
            </Route>

            <Route path="/">
              <Route path="/" element={<HomePage />} />

              <Route
                path="profile"
                element={
                  <PrivateRoute roles={[UserRole.ADMIN, UserRole.USER]} outlet={<Profile />} />
                }
              />

              <Route
                path="affiliate-report"
                element={
                  <PrivateRoute
                    roles={[UserRole.ADMIN, UserRole.USER]}
                    outlet={<AffiliateReport />}
                  />
                }
              />

              <Route
                path="set-password"
                element={
                  <PrivateRoute roles={[UserRole.ADMIN, UserRole.USER]} outlet={<SetPassword />} />
                }
              />

              {/*<Route*/}
              {/*  path="pass-kyc"*/}
              {/*  element={*/}
              {/*    <PrivateRoute roles={[UserRole.ADMIN, UserRole.USER]} outlet={<PassKYC />} />*/}
              {/*  }*/}
              {/*/>*/}

              {/*<Route*/}
              {/*  path="verify-kyc"*/}
              {/*  element={*/}
              {/*    <PrivateRoute roles={[UserRole.ADMIN, UserRole.USER]} outlet={<VerifyKYC />} />*/}
              {/*  }*/}
              {/*/>*/}
            </Route>

            <Route path="/contact-us" element={<ContactUs />} />

            <Route path="/faq" element={<FAQ />} />

            <Route path="/about-us" element={<AboutUs />} />

            <Route path="/airdrop-bounty" element={<AirdropBounty />} />

            <Route path="/newsletter" element={<Newsletter />} />

            <Route path="/detail/:urlSlug" element={<AirdropMobileDetail />} />

            {/*<Route path="/tulip-exosama" element={<TulipExosamaAirdrop />} />*/}

            {/*<Route path="/tulip-moonsama" element={<TulipMoonsamaAirdrop />} />*/}

            <Route path="/tulip" element={<TulipGenericPageExpired />} />

            {/*<Route path="/tulip-kyc" element={<TulipKYC />} />*/}

            {/*<Route path="/tulip-eth" element={<TulipETH />} />*/}

            {/*<Route path="/waiting-list" element={<WaitingList />} />*/}

            {/*<Route path="/confirmation-email-sent" element={<ConfirmationEmailSent />} />*/}

            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/cookie-policy" element={<CookiePolicy />} />

            <Route path="/rewards" element={<Rewards />} />

            <Route path="/tasker" element={<Tasker />} />

            <Route path="/affiliate-program" element={<Referrals />} />

            <Route
              path="/telegram-channel"
              element={<RedirectTo link={'https://t.me/airdrop_com_ann'} />}
            />

            <Route
              path="/telegram-group"
              element={<RedirectTo link={'https://t.me/airdrop_com_tg'} />}
            />

            <Route
              path="/discord-invite"
              element={<RedirectTo link={'https://discord.gg/9E3e3BK4t9'} />}
            />

            <Route
              path="/twitter-page"
              element={<RedirectTo link={'https://twitter.com/AIRDROP__COM'} />}
            />
          </Route>

          {/*Auth*/}
          <Route path="/login" element={<LoginPage />} />

          <Route path="/r/:referralCode" element={<ReferralSignup />} />

          <Route path="/campaign" element={<Campaign />} />

          <Route path="/sign-up" element={<RegisterPage />} />

          <Route path="/verify-email-sent" element={<VerifyEmailSent />} />

          <Route path="/confirm-email" element={<ConfirmEmail />} />

          <Route path="/change-email" element={<ConfirmChangeEmail />} />

          <Route path="/revert-email" element={<RevertEmail />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route path="/recover-password-sent" element={<RecoverPasswordSent />} />

          <Route path="/forget-password" element={<RecoverPassword />} />

          <Route path="/login/2fa/qr-code" element={<TwoFAQrCode />} />

          <Route path="/login/2fa/code" element={<TwoFACode />} />

          <Route path="/google" element={<ConfirmGoogleAccount />} />

          <Route path="/facebook" element={<ConfirmFacebookAccount />} />

          <Route path="/x" element={<ConfirmTwitterAccount />} />

          <Route path="/tasker-waitlist" element={<TaskerWaitListPage />} />
          {/*Auth*/}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {/*{!!userToken && <PreferencesModal />}*/}
    </BrowserRouter>
  );
};

export default AppRouter;

/* 
    for prevent create axios instance 
    we just import it in index.tsx
*/
import { authSlice } from '@stores/slices/auth.slice';
import { sendToastNotification } from '../utils/main.utils';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

axios.interceptors.request.use((request) => {
  const userToken = (window as any)?.store.getState()?.auth?.token;
  if (userToken && request.headers) request.headers.Authorization = `Bearer ${userToken}`;

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.request?.status === 401) {
      (window as any)?.store.dispatch(authSlice.actions.unRegister());
      return Promise.reject(error);
    }

    if (Array.isArray(error?.response?.data?.message)) {
      error?.response?.data?.message?.map((item: any) => {
        Object.values(item.constraints).map((err) => {
          if (error.code !== 'ECONNABORTED' && error.response.data.showError) {
            sendToastNotification(err as string, 'error');
          }
        });
      });
    } else {
      if (error.code !== 'ECONNABORTED' && error.response.data.showError) {
        sendToastNotification(error?.response?.data?.message, 'error');
      }
    }
    return Promise.reject(error);
  },
);

export {};

/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authSaga } from '@stores/sagas/auth.saga';
import { UserRole } from '@utils/main.utils';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
  LoginResponseModel,
  LoginUserModel,
  SetMewPasswordModel,
  RegisterNewUserModel,
} from '../../types/auth';

export interface AuthStateType {
  status: 'UNREGISTER' | 'LOADING' | 'LOGIN_SUCCESS' | 'TWO_FA_SUCCESS' | 'ERROR';
  error: any;
  token: string | null;
  role: UserRole | null;
  registerStatus?:
    | 'IDLE'
    | 'LOADING'
    | 'REGISTER_SUCCESS'
    | 'ERROR'
    | 'APPROVING'
    | 'APPROVE_SUCCESS'
    | 'APPROVE_FAIL';
  registerEmail?: string;
  registerToken?: string;
  registerError?: any;
  recoverStatus?:
    | 'IDLE'
    | 'LOADING'
    | 'SUCCESS'
    | 'ERROR'
    | 'VERIFYING'
    | 'APPROVING'
    | 'APPROVE_SUCCESS'
    | 'APPROVE_FAIL'
    | 'SETTING_PASSWORD'
    | 'SETTING_PASSWORD_SUCCESS'
    | 'SETTING_PASSWORD_FAIL';
  recoverEmail?: string;
  recoverError?: any;
  qrCode?: string | null;
  isSocialRegister?: boolean;
}

export const authInitialState: AuthStateType = {
  status: 'UNREGISTER',
  error: null,
  token: null,
  role: null,
  registerStatus: 'IDLE',
  registerEmail: '',
  registerError: null,
  registerToken: '',
  recoverStatus: 'IDLE',
  recoverEmail: '',
  recoverError: null,
  qrCode: null,
  isSocialRegister: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    login(state: AuthStateType, action?: PayloadAction<LoginUserModel>) {
      state.status = 'LOADING';
      state.error = null;
    },
    loginSuccess(state: AuthStateType, action: PayloadAction<LoginResponseModel>) {
      state.status = 'LOGIN_SUCCESS';
      state.error = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.qrCode = action.payload.qrCode;
    },
    loginFail(state: AuthStateType, action: PayloadAction<{ error: any }>) {
      state.status = 'ERROR';
      state.error = null;
      state.error = action.payload.error;
    },
    register(state: AuthStateType, action?: PayloadAction<RegisterNewUserModel>) {
      state.registerStatus = 'LOADING';
      state.registerError = null;
      state.registerEmail = action?.payload.email;
    },
    registerSuccess(state: AuthStateType) {
      state.registerStatus = 'REGISTER_SUCCESS';
      state.error = null;
    },
    registerFail(state: AuthStateType, action?: PayloadAction<{ error: any }>) {
      state.registerStatus = 'ERROR';
      state.error = action?.payload.error;
    },
    confirmGoogle(
      state: AuthStateType,
      action: PayloadAction<{ code: string; referralCode?: string | null }>,
    ) {
      state.registerStatus = 'LOADING';
    },
    confirmGoogleSuccess(
      state: AuthStateType,
      action: PayloadAction<{ token: string; role: UserRole; qrCode?: string; register?: boolean }>,
    ) {
      if (action.payload.register) {
        state.isSocialRegister = action.payload.register;
      }
      state.registerStatus = 'REGISTER_SUCCESS';
      state.status = 'LOGIN_SUCCESS';
      state.error = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.qrCode = action.payload.qrCode;
    },
    confirmGoogleFail(state: AuthStateType, action: PayloadAction<{ error: any }>) {
      state.registerStatus = 'ERROR';
      state.error = action.payload.error;
    },
    confirmFacebook(
      state: AuthStateType,
      action: PayloadAction<{ code: string; referralCode?: string | null }>,
    ) {
      state.registerStatus = 'LOADING';
    },
    confirmFacebookSuccess(
      state: AuthStateType,
      action: PayloadAction<{ token: string; role: UserRole; qrCode?: string; register?: boolean }>,
    ) {
      if (action.payload.register) {
        state.isSocialRegister = action.payload.register;
      }
      state.registerStatus = 'REGISTER_SUCCESS';
      state.status = 'LOGIN_SUCCESS';
      state.error = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.qrCode = action.payload.qrCode;
    },
    confirmFacebookFail(state: AuthStateType, action: PayloadAction<{ error: any }>) {
      state.registerStatus = 'ERROR';
      state.error = action.payload.error;
    },
    approveEmail(state: AuthStateType, action?: PayloadAction<{ token: string }>) {
      state.registerStatus = 'APPROVING';
      state.registerToken = action?.payload.token;
    },
    approveEmailSuccess(state: AuthStateType, action: PayloadAction<LoginResponseModel>) {
      state.registerStatus = 'APPROVE_SUCCESS';
      state.status = 'LOGIN_SUCCESS';
      state.registerError = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.qrCode = action.payload.qrCode;
    },
    approveEmailError(state: AuthStateType, action?: PayloadAction<{ error: any }>) {
      state.registerStatus = 'APPROVE_FAIL';
      state.registerError = action?.payload.error;
    },
    approveForgetEmail(state: AuthStateType, action?: PayloadAction<{ token: string }>) {
      state.recoverStatus = 'APPROVING';
      state.registerToken = action?.payload.token;
    },
    approveForgetEmailSuccess(state: AuthStateType) {
      state.recoverStatus = 'APPROVE_SUCCESS';
    },
    approveForgetEmailFail(state: AuthStateType, action?: PayloadAction<{ error: any }>) {
      state.recoverStatus = 'APPROVE_FAIL';
      state.registerError = action?.payload.error;
    },
    setNewPassword(state: AuthStateType, action?: PayloadAction<SetMewPasswordModel>) {
      state.recoverStatus = 'SETTING_PASSWORD';
      state.registerToken = action?.payload.token;
    },
    setNewPasswordSuccess(state: AuthStateType) {
      state.recoverStatus = 'SETTING_PASSWORD_SUCCESS';
    },
    setNewPasswordFail(state: AuthStateType, action?: PayloadAction<{ error: any }>) {
      state.recoverStatus = 'SETTING_PASSWORD_FAIL';
      state.recoverError = action?.payload.error;
    },
    resendVerificationLink(
      state: AuthStateType,
      action?: PayloadAction<{ token: string; redirectUrl?: string }>,
    ) {
      state.registerToken = action?.payload.token;
    },
    clearRegisterState(state: AuthStateType) {
      state.registerStatus = 'IDLE';
      state.registerEmail = '';
      state.registerToken = '';
      state.registerError = null;
    },
    validateTwoFACode(state: AuthStateType, _actions?: PayloadAction<{ code: string }>) {
      state.status = 'LOADING';
    },
    validateTwoFACodeSuccess(
      state: AuthStateType,
      action: PayloadAction<{ token: string; role: UserRole }>,
    ) {
      state.status = 'TWO_FA_SUCCESS';
      state.error = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
    validateTwoFACodeFail(state: AuthStateType, action: PayloadAction<{ error: any }>) {
      state.status = 'ERROR';
      state.error = null;
      state.error = action.payload.error;
    },
    unRegister(state: AuthStateType) {
      state.status = 'UNREGISTER';
      state.error = null;
      state.token = null;
      state.role = null;
      state.qrCode = null;
    },
    forgetPassword(state: AuthStateType, action: PayloadAction<{ email: string }>) {
      state.recoverStatus = 'LOADING';
      state.recoverEmail = action.payload.email;
    },
    forgetPasswordSuccess(state: AuthStateType) {
      state.recoverStatus = 'SUCCESS';
    },
    forgetPasswordFail(state: AuthStateType, action?: PayloadAction<{ error: any }>) {
      state.recoverStatus = 'ERROR';
      state.recoverError = action?.payload.error;
    },
    clearRecoverState(state: AuthStateType) {
      state.recoverStatus = 'IDLE';
      state.recoverEmail = '';
      state.recoverError = null;
    },
    logout() {
      try {
        localStorage.removeItem('state');
        localStorage.removeItem('preferencesModalOpened');
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return authInitialState;
    },
    setState(state: AuthStateType, action: PayloadAction<Partial<AuthStateType>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { actions: authActions, reducer } = authSlice;

export const useAuthSlice = () => {
  useInjectReducer({ key: authSlice.name, reducer: authSlice.reducer });
  useInjectSaga({ key: authSlice.name, saga: authSaga });

  return { actions: authSlice.actions };
};

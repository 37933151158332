import './App.scss';
import AppRouter from './routes/index';
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-toastify';

function App() {
  useEffect(() => {
    try {
      if (!localStorage.getItem('viewToken')) {
        const viewToken = uuidv4();
        localStorage.setItem('viewToken', viewToken);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  return (
    <div className="App">
      <ToastContainer
        hideProgressBar
        position="bottom-left"
        toastStyle={{
          minHeight: 'initial',
        }}
      />
      <AppRouter />
    </div>
  );
}

export default App;

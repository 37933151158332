/* eslint-disable no-unused-vars */
import { userSaga } from '@stores/sagas/user.saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IKYCSession, Preferences, UserModel } from '../../types/user';
import { CreateWalletModel, EditWalletModel, Wallet } from '../../types/wallet';
import { IAdminAirdropTaskerDetails } from '../../types/tasker';
import { AdminUserDetailStateType } from '@stores/slices/admin/user/admin.user.detail.slice';

export interface UserStateType {
  status: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  error: any;
  user: UserModel | null;
  personalInfoStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  personalInfoError: any;
  deleteAccountStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  deleteAccountError: any;
  changePasswordStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  changePasswordError: any;
  changeEmailStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  changeEmailError: any;
  approveEmailStatus: 'IDLE' | 'APPROVING' | 'APPROVE_SUCCESS' | 'APPROVE_FAIL';
  approveEmailToken?: string;
  approveEmailError: any;
  revertEmailStatus: 'IDLE' | 'APPROVING' | 'APPROVE_SUCCESS' | 'APPROVE_FAIL';
  revertEmailToken?: string;
  revertEmailError: any;
  setPasswordStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  setPasswordError: any;
  preferencesModalIsVisible: boolean;
  preferencesStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR' | 'SUCCESS_SET';
  preferencesError: any;
  preferences: Preferences | null;
  cookieBannerIsShown: boolean;
  selectCookieIsEnabled: boolean;
  walletsStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  walletsError: any;
  createWalletStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  createWalletError: any;
  editWalletStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  editWalletError: any;
  kycSession: IKYCSession | null;
  kycSessionStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  kycSessionError: any;
  verifyKycStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  verifyKycError: any;
  wallets: Wallet[];
  checkClaimAddressStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  checkClaimAddressError: any;
  addClaimAddressStatus: 'IDLE' | 'LOADING' | 'SUCCESS' | 'ERROR';
  addClaimAddressError: any;
  taskerStatus: 'LOADING' | 'SUCCESS' | 'ERROR' | 'DEFAULT';
  taskerError: any;
  taskerData: IAdminAirdropTaskerDetails | null;
}

export const userInitialState: UserStateType = {
  status: 'IDLE',
  error: null,
  user: null,
  personalInfoStatus: 'IDLE',
  personalInfoError: null,
  deleteAccountStatus: 'IDLE',
  deleteAccountError: null,
  changePasswordStatus: 'IDLE',
  changePasswordError: null,
  changeEmailStatus: 'IDLE',
  changeEmailError: null,
  approveEmailStatus: 'IDLE',
  approveEmailToken: '',
  approveEmailError: null,
  revertEmailStatus: 'IDLE',
  revertEmailToken: '',
  revertEmailError: null,
  setPasswordStatus: 'IDLE',
  setPasswordError: null,
  preferencesModalIsVisible: false,
  preferencesStatus: 'IDLE',
  preferencesError: null,
  preferences: null,
  cookieBannerIsShown: false,
  selectCookieIsEnabled: true,
  walletsStatus: 'IDLE',
  walletsError: null,
  createWalletStatus: 'IDLE',
  createWalletError: null,
  editWalletStatus: 'IDLE',
  editWalletError: null,
  wallets: [],
  kycSession: null,
  kycSessionStatus: 'IDLE',
  kycSessionError: null,
  verifyKycStatus: 'IDLE',
  verifyKycError: null,
  checkClaimAddressStatus: 'IDLE',
  checkClaimAddressError: null,
  addClaimAddressStatus: 'IDLE',
  addClaimAddressError: null,
  taskerStatus: 'DEFAULT',
  taskerError: null,
  taskerData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    getUser(state: UserStateType) {
      state.status = 'LOADING';
    },
    getUserSuccess(state: UserStateType, action: PayloadAction<UserModel>) {
      state.status = 'SUCCESS';
      state.user = action?.payload;
    },
    getUserFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.status = 'ERROR';
      state.error = action?.payload.error;
    },
    setUserReferralCode(state: UserStateType, action: PayloadAction<{ referralCode: string }>) {
      if (state.user) {
        state.user.airdropReferralCode = action.payload.referralCode;
      }
    },
    updateUserPersonalInfo(
      state: UserStateType,
      action: PayloadAction<Pick<UserModel, 'firstName' | 'lastName'>>,
    ) {
      state.personalInfoStatus = 'LOADING';
    },
    updateUserPersonalInfoSuccess(state: UserStateType, action: PayloadAction<UserModel>) {
      state.user = action.payload;
      state.personalInfoStatus = 'SUCCESS';
    },
    updateUserPersonalInfoFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.personalInfoStatus = 'ERROR';
      state.personalInfoError = action?.payload.error;
    },
    updateUserPersonalInfo2(
      state: UserStateType,
      action: PayloadAction<{ firstName?: string; lastName?: string; picture?: File | '' }>,
    ) {
      state.personalInfoStatus = 'LOADING';
    },
    updateUserPersonalInfo2Success(state: UserStateType, action: PayloadAction<UserModel>) {
      state.user = action.payload;
      state.personalInfoStatus = 'SUCCESS';
    },
    updateUserPersonalInfo2Fail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.personalInfoStatus = 'ERROR';
      state.personalInfoError = action?.payload.error;
    },
    clearUserPersonalInfoData(state: UserStateType) {
      state.personalInfoStatus = 'IDLE';
      state.personalInfoError = null;
    },
    deleteAccount(state: UserStateType, action: PayloadAction<{ password: string }>) {
      state.deleteAccountStatus = 'LOADING';
    },
    deleteAccountSuccess(state: UserStateType, action: PayloadAction<UserModel>) {
      state.user = action.payload;
      state.deleteAccountStatus = 'SUCCESS';
    },
    deleteAccountFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.deleteAccountStatus = 'ERROR';
      state.deleteAccountError = action?.payload.error;
    },
    clearDeleteAccountData(state: UserStateType) {
      state.deleteAccountStatus = 'IDLE';
      state.deleteAccountError = null;
    },
    // CHANGE PASSWORD
    changePassword(
      state: UserStateType,
      action: PayloadAction<{ password?: string; newPassword?: string }>,
    ) {
      state.changePasswordStatus = 'LOADING';
    },
    changePasswordSuccess(state: UserStateType) {
      state.changePasswordStatus = 'SUCCESS';
    },
    changePasswordFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.changePasswordStatus = 'ERROR';
      state.changePasswordError = action?.payload.error;
    },
    clearChangePasswordData(state: UserStateType) {
      state.changePasswordStatus = 'IDLE';
      state.changePasswordError = null;
    },
    // CHANGE EMAIL
    changeEmail(state: UserStateType, action: PayloadAction<{ email: string; password: string }>) {
      state.changeEmailStatus = 'LOADING';
    },
    changeEmailSuccess(
      state: UserStateType,
      action: PayloadAction<{ email: string; password: string }>,
    ) {
      state.changeEmailStatus = 'SUCCESS';
      if (state.user) {
        state.user = { ...state.user, pendingNewEmail: action.payload.email };
      }
    },
    changeEmailFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.changeEmailStatus = 'ERROR';
      state.changeEmailError = action?.payload.error;
    },
    clearChangeEmailData(state: UserStateType) {
      state.changeEmailStatus = 'IDLE';
      state.changeEmailError = null;
    },
    // APPROVE EMAIL
    approveEmail(state: UserStateType, action?: PayloadAction<{ token: string }>) {
      state.approveEmailStatus = 'APPROVING';
      state.approveEmailToken = action?.payload.token;
    },
    approveEmailSuccess(state: UserStateType) {
      state.approveEmailStatus = 'APPROVE_SUCCESS';
    },
    approveEmailFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.approveEmailStatus = 'APPROVE_FAIL';
      state.approveEmailToken = '';
      state.approveEmailError = action?.payload.error;
    },
    clearApproveEmailData(state: UserStateType) {
      state.approveEmailStatus = 'IDLE';
      state.approveEmailError = null;
    },
    // REVERT EMAIL
    revertEmail(state: UserStateType, action?: PayloadAction<{ token: string }>) {
      state.revertEmailStatus = 'APPROVING';
      state.revertEmailToken = action?.payload.token;
    },
    revertEmailSuccess(state: UserStateType) {
      state.revertEmailStatus = 'APPROVE_SUCCESS';
    },
    revertEmailFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.revertEmailStatus = 'APPROVE_FAIL';
      state.revertEmailError = action?.payload.error;
    },
    clearRevertEmailData(state: UserStateType) {
      state.revertEmailStatus = 'IDLE';
      state.revertEmailToken = '';
      state.revertEmailError = null;
    },
    // SET PASSWORD
    setPassword(state: UserStateType, action: PayloadAction<{ password: string }>) {
      state.setPasswordStatus = 'LOADING';
    },
    setPasswordSuccess(state: UserStateType) {
      state.setPasswordStatus = 'SUCCESS';
      if (state.user) {
        state.user = { ...state.user, hasPassword: true };
      }
    },
    setPasswordFail(state: UserStateType, action?: PayloadAction<{ error: any }>) {
      state.setPasswordStatus = 'ERROR';
      state.setPasswordError = action?.payload.error;
    },
    clearSetPasswordData(state: UserStateType) {
      state.setPasswordStatus = 'IDLE';
      state.setPasswordError = null;
    },
    // PREFERENCES
    togglePreferencesModal(state: UserStateType, action: PayloadAction<boolean>) {
      state.preferencesModalIsVisible = action.payload;
    },
    getPreferences(state: UserStateType) {
      state.preferencesStatus = 'LOADING';
    },
    getPreferencesSuccess(state: UserStateType, action: PayloadAction<Preferences>) {
      state.preferencesStatus = 'SUCCESS';
      state.preferences = action.payload;
    },
    getPreferencesFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.preferencesStatus = 'ERROR';
      state.preferencesError = action.payload.error;
    },
    setPreferences(state: UserStateType, action: PayloadAction<Preferences>) {
      state.preferencesStatus = 'LOADING';
    },
    setPreferencesSuccess(state: UserStateType, action: PayloadAction<Preferences>) {
      state.preferencesStatus = 'SUCCESS_SET';
      state.preferences = action.payload;
    },
    setPreferencesFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.preferencesStatus = 'ERROR';
      state.preferencesError = action.payload.error;
    },
    clearPreferenceData(state: UserStateType) {
      state.preferencesStatus = 'IDLE';
      state.preferencesError = null;
    },
    // Wallets
    getWallets(state: UserStateType) {
      state.walletsStatus = 'LOADING';
    },
    getWalletsSuccess(state: UserStateType, action: PayloadAction<Wallet[]>) {
      state.walletsStatus = 'SUCCESS';
      state.wallets = action.payload;
    },
    getWalletsFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.walletsStatus = 'ERROR';
      state.walletsError = action.payload.error;
    },
    createWallet(state: UserStateType, action: PayloadAction<CreateWalletModel>) {
      state.createWalletStatus = 'LOADING';
    },
    createWalletSuccess(state: UserStateType, action: PayloadAction<Wallet>) {
      state.createWalletStatus = 'SUCCESS';
      state.wallets = [action.payload];
    },
    createWalletFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.createWalletStatus = 'ERROR';
      state.createWalletError = action.payload.error;
    },
    editWallet(state: UserStateType, action: PayloadAction<EditWalletModel>) {
      state.editWalletStatus = 'LOADING';
    },
    editWalletSuccess(state: UserStateType, action: PayloadAction<Wallet>) {
      state.editWalletStatus = 'SUCCESS';
      state.wallets = [action.payload];
    },
    editWalletFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.editWalletStatus = 'ERROR';
      state.editWalletError = action.payload.error;
    },
    toggleCookieBannerIsShown(state, action: PayloadAction<boolean>) {
      state.cookieBannerIsShown = action.payload;
    },
    toggleCookieIsEnabled(state, action: PayloadAction<boolean>) {
      state.selectCookieIsEnabled = action.payload;
    },
    getKycSession(state: UserStateType) {
      state.kycSessionStatus = 'LOADING';
    },
    getKycSessionSuccess(state: UserStateType, action: PayloadAction<IKYCSession>) {
      state.kycSession = action.payload;
      state.kycSessionStatus = 'SUCCESS';
    },
    getKycSessionFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.kycSessionStatus = 'ERROR';
      state.kycSessionError = action.payload;
    },
    resetKycSession(state: UserStateType) {
      state.kycSessionStatus = 'IDLE';
      state.kycSessionError = null;
    },
    verifyKyc(state: UserStateType, action: PayloadAction<{ reference: string }>) {
      state.verifyKycStatus = 'LOADING';
    },
    verifyKycSuccess(state: UserStateType) {
      if (state.user) state.user.passedKYC = true;
      state.verifyKycStatus = 'SUCCESS';
    },
    verifyKycFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.verifyKycStatus = 'ERROR';
      state.verifyKycError = action.payload;
    },
    resetVerifyKyc(state: UserStateType) {
      state.verifyKycStatus = 'IDLE';
      state.verifyKycError = null;
    },
    checkClaimAddress(state: UserStateType, action: PayloadAction<{ address: string }>) {
      state.checkClaimAddressStatus = 'LOADING';
    },
    checkClaimAddressSuccess(state: UserStateType) {
      state.checkClaimAddressStatus = 'SUCCESS';
    },
    checkClaimAddressFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.checkClaimAddressStatus = 'ERROR';
      state.checkClaimAddressError = action.payload;
    },
    resetCheckClaimAddress(state: UserStateType) {
      state.checkClaimAddressStatus = 'IDLE';
      state.checkClaimAddressError = null;
    },
    addClaimAddress(
      state: UserStateType,
      action: PayloadAction<{
        address: string;
        signedMessage: string;
        currencyTicker: string;
      }>,
    ) {
      state.addClaimAddressStatus = 'LOADING';
    },
    addClaimAddressSuccess(state: UserStateType, action: PayloadAction<Wallet>) {
      state.addClaimAddressStatus = 'SUCCESS';
      if (state.user) {
        const claimWallets = state.user?.claimWallets || [];
        claimWallets.push(action.payload);
        state.user = {
          ...state.user,
          claimWallets,
        };
      }
    },
    addClaimAddressFail(state: UserStateType, action: PayloadAction<{ error: any }>) {
      state.addClaimAddressStatus = 'ERROR';
      state.addClaimAddressError = action.payload;
    },
    resetAddClaimAddress(state: UserStateType) {
      state.addClaimAddressStatus = 'IDLE';
      state.addClaimAddressError = null;
    },
    userTaskerDetails(state: UserStateType) {
      state.taskerStatus = 'LOADING';
      state.taskerError = null;
      state.taskerData = null;
    },
    userTaskerDetailSuccess(
      state: UserStateType,
      action: PayloadAction<{ data: IAdminAirdropTaskerDetails }>,
    ) {
      state.taskerStatus = 'SUCCESS';
      state.taskerError = null;
      state.taskerData = action.payload.data;
    },
    userTaskerDetailFail(
      state: UserStateType,
      action: PayloadAction<{ resetData: boolean; error: any }>,
    ) {
      state.taskerStatus = 'ERROR';
      if (action.payload.resetData) state.error = null;
      state.taskerError = action.payload.error;
    },
    reset() {
      return userInitialState;
    },
  },
});

export const { actions: userActions, reducer } = userSlice;

export const useUserSlice = () => {
  useInjectReducer({ key: userSlice.name, reducer: userSlice.reducer });
  useInjectSaga({ key: userSlice.name, saga: userSaga });

  return { actions: userActions };
};

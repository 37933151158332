import { airdropApi } from '../../api/airdrop';
import { userActions } from '@stores/slices/user.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { IKYCSession, Preferences, UserModel } from '../../types/user';
import { call, put, takeLatest } from 'redux-saga/effects';
import { CreateWalletModel, EditWalletModel, Wallet } from '../../types/wallet';
import { IAdminAirdropTaskerDetails } from '../../types/tasker';

export function* getUser(): Generator<any> {
  try {
    const user = yield call(airdropApi.user.getUser);
    yield put(userActions.getUserSuccess(user as UserModel));
  } catch (error: any) {
    yield put(userActions.getUserFail({ error: error?.response?.data }));
  }
}

export function* updateUserPersonalInfo({
  payload,
}: PayloadAction<Pick<UserModel, 'firstName' | 'lastName'>>): Generator<any> {
  try {
    const user = yield call(airdropApi.user.updateUserPersonalInfo, payload);
    yield put(userActions.updateUserPersonalInfoSuccess(user as UserModel));
  } catch (error: any) {
    yield put(userActions.updateUserPersonalInfoFail({ error: error?.response?.data }));
  }
}

export function* updateUserPersonalInfo2({
  payload,
}: PayloadAction<Pick<UserModel, 'firstName' | 'lastName' | 'picture'>>): Generator<any> {
  try {
    const user = yield call(airdropApi.user.updateUserPersonalInfo2, payload);
    yield put(userActions.updateUserPersonalInfo2Success(user as UserModel));
  } catch (error: any) {
    yield put(userActions.updateUserPersonalInfo2Fail({ error: error?.response?.data }));
  }
}

export function* deleteAccount({ payload }: PayloadAction<{ password: string }>): Generator<any> {
  try {
    const user = yield call(airdropApi.user.deleteAccount, payload);
    yield put(userActions.deleteAccountSuccess(user as UserModel));
  } catch (error: any) {
    yield put(userActions.deleteAccountFail({ error: error?.response?.data }));
  }
}

export function* changePassword({
  payload,
}: PayloadAction<{ password: string; newPassword: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.changePassword, payload);
    yield put(userActions.changePasswordSuccess());
  } catch (error: any) {
    yield put(userActions.changePasswordFail({ error: error?.response?.data }));
  }
}

export function* changeEmail({
  payload,
}: PayloadAction<{ email: string; password: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.changeEmail, payload);
    yield put(userActions.changeEmailSuccess(payload));
  } catch (error: any) {
    yield put(userActions.changeEmailFail({ error: error?.response?.data }));
  }
}

export function* approveEmail({ payload }: PayloadAction<{ token: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.approveEmail, payload);
    yield put(userActions.approveEmailSuccess());
  } catch (error: any) {
    yield put(userActions.approveEmailFail({ error }));
  }
}

export function* revertEmail({ payload }: PayloadAction<{ token: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.revertEmail, payload);
    yield put(userActions.revertEmailSuccess());
  } catch (error: any) {
    yield put(userActions.revertEmailFail({ error }));
  }
}

export function* setPassword({ payload }: PayloadAction<{ password: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.setPassword, payload);
    yield put(userActions.setPasswordSuccess());
  } catch (error: any) {
    yield put(userActions.setPasswordFail({ error: error?.response?.data }));
  }
}

export function* getPreferences(): Generator<any> {
  try {
    const preferences = yield call(airdropApi.user.getPreferences);
    yield put(userActions.getPreferencesSuccess(preferences as Preferences));
  } catch (error: any) {
    yield put(userActions.getPreferencesFail({ error: error?.response?.data }));
  }
}

export function* setPreferences({ payload }: PayloadAction<Preferences>): Generator<any> {
  try {
    const preferences = yield call(airdropApi.user.setPreferences, payload);
    yield put(userActions.setPreferencesSuccess(preferences as Preferences));
  } catch (error: any) {
    yield put(userActions.setPreferencesFail({ error: error?.response?.data }));
  }
}

export function* getWallets(): Generator<any> {
  try {
    const wallets = yield call(airdropApi.user.getWallets);
    yield put(userActions.getWalletsSuccess(wallets as Wallet[]));
  } catch (error: any) {
    yield put(userActions.getWalletsFail({ error: error?.response?.data }));
  }
}

export function* createWallet({ payload }: PayloadAction<CreateWalletModel>): Generator<any> {
  try {
    const wallet = yield call(airdropApi.user.createWallet, payload);
    yield put(userActions.createWalletSuccess(wallet as Wallet));
  } catch (error: any) {
    yield put(userActions.createWalletFail({ error: error?.response?.data }));
  }
}

export function* editWallet({ payload }: PayloadAction<EditWalletModel>): Generator<any> {
  try {
    const wallet = yield call(airdropApi.user.editWallet, payload);
    yield put(userActions.editWalletSuccess(wallet as Wallet));
  } catch (error: any) {
    yield put(userActions.editWalletFail({ error: error?.response?.data }));
  }
}

export function* getKycSession(): Generator<any> {
  try {
    const kycSession = yield call(airdropApi.user.getKycSession);
    yield put(userActions.getKycSessionSuccess(kycSession as IKYCSession));
  } catch (error: any) {
    yield put(userActions.getKycSessionFail({ error: error?.response?.data }));
  }
}

export function* verifyKyc({ payload }: PayloadAction<{ reference: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.verifyKyc, payload);
    yield put(userActions.verifyKycSuccess());
  } catch (error: any) {
    yield put(userActions.verifyKycFail({ error: error?.response?.data }));
  }
}

export function* checkClaimAddress({
  payload,
}: PayloadAction<{ address: string }>): Generator<any> {
  try {
    yield call(airdropApi.user.checkClaimAddress, payload.address);
    yield put(userActions.checkClaimAddressSuccess());
  } catch (error: any) {
    yield put(userActions.checkClaimAddressFail({ error: error?.response?.data }));
  }
}

export function* addClaimAddress({
  payload,
}: PayloadAction<{
  address: string;
  signedMessage: string;
  currencyTicker: string;
}>): Generator<any> {
  try {
    const wallet = yield call(airdropApi.user.addClaimAddress, payload);
    yield put(userActions.addClaimAddressSuccess(wallet as Wallet));
  } catch (error: any) {
    yield put(userActions.addClaimAddressFail({ error: error?.response?.data }));
  }
}

export function* userTaskerDetails(): Generator<any> {
  try {
    const detail = yield call(airdropApi.user.myTaskerDetails);
    yield put(
      userActions.userTaskerDetailSuccess({
        data: detail as IAdminAirdropTaskerDetails,
      }),
    );
  } catch (error: any) {
    yield put(
      userActions.userTaskerDetailFail({
        resetData: true,
        error: error?.response?.data,
      }),
    );
  }
}

export function* userSaga(): Generator<any> {
  yield takeLatest(<any>userActions.getUser, getUser);
  yield takeLatest(<any>userActions.updateUserPersonalInfo, updateUserPersonalInfo);
  yield takeLatest(<any>userActions.updateUserPersonalInfo2, updateUserPersonalInfo2);
  yield takeLatest(<any>userActions.deleteAccount, deleteAccount);
  yield takeLatest(<any>userActions.changePassword, changePassword);
  yield takeLatest(<any>userActions.changeEmail, changeEmail);
  yield takeLatest(<any>userActions.approveEmail, approveEmail);
  yield takeLatest(<any>userActions.revertEmail, revertEmail);
  yield takeLatest(<any>userActions.setPassword, setPassword);
  yield takeLatest(<any>userActions.getPreferences, getPreferences);
  yield takeLatest(<any>userActions.setPreferences, setPreferences);
  yield takeLatest(<any>userActions.getWallets, getWallets);
  yield takeLatest(<any>userActions.createWallet, createWallet);
  yield takeLatest(<any>userActions.editWallet, editWallet);
  yield takeLatest(<any>userActions.getKycSession, getKycSession);
  yield takeLatest(<any>userActions.verifyKyc, verifyKyc);
  yield takeLatest(<any>userActions.checkClaimAddress, checkClaimAddress);
  yield takeLatest(<any>userActions.addClaimAddress, addClaimAddress);
  yield takeLatest(<any>userActions.userTaskerDetails, userTaskerDetails);
}

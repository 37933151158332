import axios from 'axios';

export const issues = async () => {
  try {
    const response = await axios.get('/api/v1/issues');
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

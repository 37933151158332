import axios from 'axios';

export const ecosystems = async () => {
  try {
    const response = await axios.get('/api/v1/ecosystems');
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createEcosystem = async (data: any) => {
  try {
    const response = await axios.post('/api/v1/ecosystems', data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteEcosystem = async (id: string) => {
  try {
    const response = await axios.delete(`/api/v1/ecosystems/${id}`);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateEcosystem = async (id: string, data: any) => {
  try {
    const response = await axios.patch(`/api/v1/ecosystems/${id}`, data);
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

import { combineReducers } from '@reduxjs/toolkit';
import { InjectedReducersType } from '../types/injector-typings';
import { authSlice } from './slices/auth.slice';

/**
 * Merges the main reducer with the router
 * state and dynamically injected reducers
 */
// eslint-disable-next-line
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    // At first, we don't have any injectedReducers,
    // but since we need to fulfill auth reducer from LocalStorage,
    // we should pass the *auth* key as default
    auth: authSlice.reducer,
  });
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }, 0);
  }, [pathname, search]);

  return null;
}

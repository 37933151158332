import * as whitelist from './whitelist';
import * as auth from './auth';
import * as user from './user';
import * as airdrop from './airdrop';
import * as airdropStatus from './airdrop-status';
import * as airdropType from './airdrop-type';
import * as airdropUserType from './airdrop-user-type';
import * as ecosystem from './ecosystem';
import * as label from './label';
import * as requirement from './requirement';
import * as tokenType from './token-type';
import * as subscribers from './subscribers';
import * as issue from './issue';
import * as winnerType from './winner-type';
import * as tradableType from './tradable-type';
import * as shared from './shared';
import * as tasker from './tasker';

export type ApiFailure = {
  errors: { [fieldId: string]: string };
  message: string;
};

export const handleApiRequestFailure = (axiosError: any) => {
  const failure: ApiFailure = {
    errors: {},
    message: axiosError.messsage,
  };

  if (axiosError.response && axiosError.response.data) {
    failure.message = axiosError.response.data.error;
    if (Array.isArray(axiosError.response.data.message)) {
      for (const field of axiosError.response.data.message) {
        const errorIds = Object.keys(field.constraints);
        failure.errors[field.property] = field.constraints[errorIds[0]];
      }
    } else {
      failure.message = axiosError.response.data.message;
    }
  }

  switch (failure.message) {
    case 'Bad Request':
      failure.message = 'The form contains some errors';
      break;
    case 'ERR_JWT_EXPIRED':
      failure.message = 'The invitation expired';
      break;
    case 'ERR_UNKNOWN':
      failure.message = 'An unexpected error occured';
      break;
  }

  return Promise.reject(failure);
};

export const airdropApi = {
  whitelist,
  auth,
  user,
  airdrop,
  airdropStatus,
  airdropType,
  airdropUserType,
  ecosystem,
  label,
  requirement,
  tokenType,
  subscribers,
  issue,
  winnerType,
  tradableType,
  shared,
  tasker,
};

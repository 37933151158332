import axios from 'axios';

export const tradableTypes = async () => {
  try {
    const response = await axios.get('/api/v1/tradable-types');
    return response?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

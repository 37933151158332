/* eslint-disable no-unused-vars */
import React from 'react';
import { UAParser } from 'ua-parser-js';
import { isEqual, uniq } from 'lodash';
import { Slide, toast, ToastOptions } from 'react-toastify';
import Toast, { ToastType } from '../components/molecules/Toast/Toast';
import { ReactNode } from 'react';

export const emailRegex = /^[A-z0-9._%+-]+@[A-z0-9.-]+.[A-z]{2,4}$/;
export const regexForReplaceSymbolsInNames = /[0-9~!@#$%^&*()_\-={}[\]|"':;>.<,?/\\]/g;

export const nullIfUndefined = (value: any) => (value === undefined ? null : value);

export const removeEmptyFields = (data: Record<string, any>): void => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
};

export const setNullForEmptyValue = (data: Record<string, any>): void => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      data[key] = null;
    }
  });
};

export const objectDiff = (oldData: Record<string, any>, newData: Record<string, any>) => {
  const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);

  const diff: any = {};
  for (const key of data) if (!isEqual(oldData[key], newData[key])) diff[key] = newData[key];
  return diff;
};

export const URLRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const LowerCaseRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const dataURLtoFile = (dataurl: string, filename: string) => {
  let arr = dataurl.split(','),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export enum UserRole {
  USER = 0,
  ADMIN = 1,
}

export enum VoteType {
  UP = 1,
  DOWN = -1,
}

export enum AirdropStatus {
  OPEN = '62d63ba83a32df4010445b71',
  CLOSE = '62dfa81c7bc5d01cb69aad3d',
}

export function getMultipleRandom(arr: any[], num: number) {
  if (Array.isArray(arr)) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }
  return arr;
}

export const currentRoute = () => {
  const location = window.location;
  const path = location.pathname;

  if (/^\/login$/.test(path)) {
    return currentRoute.routNames.LOGIN;
  } else if (/^\/sign-up$/.test(path)) {
    return currentRoute.routNames.SIGNUP;
  } else if (/^\/detail\/.+$/.test(path)) {
    return currentRoute.routNames.AIRDROP_DETAILS;
  } else if (/^\/login\/2fa\/qr-code$/.test(path)) {
    return currentRoute.routNames.TWO_FA_QR;
  } else if (/^\/login\/2fa\/code$/.test(path)) {
    return currentRoute.routNames.TWO_FA;
  } else if (/^\/faq$/.test(path)) {
    return currentRoute.routNames.FAQ;
  } else if (/^\/about-us$/.test(path)) {
    return currentRoute.routNames.ABOUT_US;
  } else if (/^\/newsletter$/.test(path)) {
    return currentRoute.routNames.NEWSLETTER;
  } else if (/^\/tulip$/.test(path)) {
    return currentRoute.routNames.TULIP;
  }
  return currentRoute.routNames.UNKNOWN;
};
currentRoute.routNames = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  TWO_FA: 'TWO_FA',
  TWO_FA_QR: 'TWO_FA_QR',
  AIRDROP_DETAILS: 'AIRDROP_DETAILS',
  FAQ: 'FAQ',
  ABOUT_US: 'ABOUT_US',
  TULIP: 'TULIP',
  NEWSLETTER: 'NEWSLETTER',
  UNKNOWN: 'UNKNOWN',
};
currentRoute.is = function (routeName: string): boolean {
  return routeName === currentRoute();
};

type oSType = 'Mac OS' | 'iOS' | 'Windows' | 'Android' | 'Linux';
export const getOS = (): oSType | string => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const shareAirdrop = async (urlSlug: string) => {
  const link = `https://airdrop.com/detail/${urlSlug}`;

  if (navigator.share) navigator.share({ title: 'AIRDROP', url: link });
  else {
    navigator.clipboard.writeText(link);
    sendToastNotification('Airdrop URL Copied to Clipboard!', 'success');
  }
};

export const shareAirdropReferralCode = async (link: string, onlyCopy?: boolean) => {
  if (navigator.share && !onlyCopy) {
    navigator.share({ title: 'Tulip Code', url: link });
  } else {
    navigator.clipboard.writeText(link);
    sendToastNotification('Tulip Code Copied to Clipboard!', 'success');
  }
};

export const copy = async (text: string) => {
  !!text && navigator.clipboard.writeText(text);
};

const toastOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  rtl: false,
  closeButton: false,
};

export const sendToastNotification = (message: string | ReactNode, type: ToastType) => {
  toast(React.createElement(Toast, { message, type }), toastOptions);
};

const InjectedWalletTable: {
  [key in keyof NonNullable<Window['ethereum']>]?: { name: string };
} = {
  isBraveWallet: { name: 'Brave' },
  isRabby: { name: 'Rabby' },
  isTrust: { name: 'Trust Wallet' },
  isLedgerConnect: { name: 'Ledger' },
};

const parser = new UAParser(window.navigator.userAgent);
const { type } = parser.getDevice();

export const isMobile = type === 'mobile' || type === 'tablet';

export function getInjection(): { name: string } {
  for (const [key, wallet] of Object.entries(InjectedWalletTable)) {
    if (window.ethereum?.[key as keyof Window['ethereum']]) {
      // @ts-ignore
      return wallet;
    }
  }

  // Phantom sets its flag in a different part of the window object
  // @ts-ignore
  if (window.phantom?.ethereum?.isPhantom) return { name: 'Phantom' };

  // Check for MetaMask last, as other injectors will also set this flag, i.e. Brave browser and Phantom wallet
  if (window.ethereum?.isMetaMask) return { name: 'MetaMask' };

  // Prompt metamask installation when there is no injection present or the only injection detected is coinbase (CB has separate entry point in UI)
  if (!window.ethereum || window.ethereum.isCoinbaseWallet) return { name: 'Install MetaMask' };

  // Use a generic icon when injection is present but no known non-coinbase wallet is detected
  return { name: 'Browser Wallet' };
}

export const getIsMetaMaskWallet = () => getInjection().name === 'MetaMask';

export const getIsInjected = () => Boolean(window.ethereum);

export const getShouldAdvertiseMetaMask = () =>
  !getIsMetaMaskWallet() && !isMobile && (!getIsInjected() || getIsCoinbaseWallet());

export const getIsCoinbaseWallet = () => Boolean(window.ethereum?.isCoinbaseWallet);

export const getIsGenericInjector = () =>
  getIsInjected() && !getIsMetaMaskWallet() && !getIsCoinbaseWallet();

export const TaskerTitles = {
  REPLY: 'Reply to post',
  FOLLOW_PAGE: 'Follow to page',
  RETWEET: 'Retweet post',
  RETWEET_WITH_COMMENT: 'Retweet post with comment',
  LIKE: 'Like post',
};
